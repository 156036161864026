import React,{ Component,Fragment } from 'react';
import ContentEditable from 'react-contenteditable'
import remove from '../../../Public/Images/remove.svg';
import settings from '../../../Public/Images/settings.svg';
import parent from '../../../Public/Images/parent.svg';
import bold from '../../../Public/Images/bold.svg';
import italic from '../../../Public/Images/italic.svg';
import link from '../../../Public/Images/link.svg';
import TextFormat from '../Library/textFormat';
import move from '../../../Public/Images/move.svg';

const INITIAL_STATE = {
    target:"_blank",
    url:"",
    hBold:false,
    hItalic:false,
    hLink:false,
    focusIndex: 0
}

class ParagraphElement extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;

        this.setLinkData       = this.setLinkData.bind(this);
        this.setSelectedText   = this.setSelectedText.bind(this);

        var refs = [];
        if(props.element.listItems && props.element.listItems.length > 0){
            for(let i in props.element.listItems){
                refs.push(React.createRef());
            }
        }
        this.inputRef = refs;
    }

    componentDidMount(){
        this.tf = new TextFormat(this,null,null,"p");
    }

    setLinkData(e){

        var type = e.target.name;
        var value = e.target.value;

        this.setState({[type]:value});
    }

    setSelectedText(){

        var idoc = this.props.iframeNode.contentDocument || this.props.iframeNode.contentWindow.document;
        var selObj = idoc.getSelection();
        
        this.selRange     = selObj.getRangeAt(0);
        this.selectedText = selObj.toString()

    }

    handleKeyPressLi(e){
        
        if(e.keyCode == 8){
            
            var text = this.props.element.listItems[this.state.focusIndex].text[this.props.lang];
            if(text == ""){
                this.inputRef.splice(this.state.focusIndex,1);
                this.props.cm.deleteListItem(this.props.lastIndexes,this.state.focusIndex,() => {
                    if(this.inputRef[this.state.focusIndex-1])this.inputRef[this.state.focusIndex-1].current.focus();
                });
            }

        }
        if(e.keyCode == 13 && !e.shiftKey){

            this.inputRef.splice(this.state.focusIndex + 1,0,React.createRef());
            this.props.cm.addListItem(this.props.lastIndexes,this.state.focusIndex,this.props.allLanguages,() => {
                this.inputRef[this.state.focusIndex+1].current.focus();
            });
            e.preventDefault();
            e.stopPropagation();
        }

    }

    onPaste(e){
        e.preventDefault(); 
        var text = e.clipboardData.getData('text/plain'); 
        text = this.tf.setPlainText(this.props.iframeNode,text);
        if(text != "")this.props.cm.setElementData("text",this.props.lastIndexes,"list",text,null,true,this.state.focusIndex);
    }

    setLink(e,lastIndexes,highlights){

        e.preventDefault();
        e.stopPropagation();

        var attr = {
            href:this.state.url
        }

        if(this.state.target != "")attr["target"] = this.state.target;

        var tf = new TextFormat(this,this.selRange,this.selectedText,"p");
        var text = tf.setLink(this.props.iframeNode,attr,highlights);

        if(text){
            this.props.cm.showLinkSettings("list",lastIndexes,() =>{
                this.props.cm.setElementData("text",lastIndexes,"list",text,() => {
                   
                    this.inputRef[this.state.focusIndex].current.focus();
                    this.inputRef[this.state.focusIndex].current.blur();
                },true,this.state.focusIndex);
            });
        }else{
            this.props.cm.showLinkSettings("list",lastIndexes,() =>{
                this.inputRef[this.state.focusIndex].current.focus();
                this.inputRef[this.state.focusIndex].current.blur();   
            });
        }
 
    }

    setBold(){
        var text = this.tf.setBold(this.props.iframeNode,this.state.hBold);
        if(text != "")this.props.cm.setElementData("text",this.props.lastIndexes,"list",text,null,true,this.state.focusIndex);
    }

    setItalic(){
        var text = this.tf.setItalic(this.props.iframeNode,this.state.hItalic);
        if(text != "")this.props.cm.setElementData("text",this.props.lastIndexes,"list",text,null,true,this.state.focusIndex);
    }

    render(){

        const {element,showHelpers,cm,lastIndexes,iframeNode,lang} = this.props;
        const {hBold,hItalic,hLink,focusIndex} = this.state;

        var parentIndexes = "";
        
        var indexes = lastIndexes.split("-");
        if(indexes.length > 2){
            indexes.pop();
            parentIndexes = indexes.join("-");
        }

        
        return (
            <div className="cm-headline">

                {showHelpers && element.showMenu &&
                    <div className="cm-menu" onClick={(e) => e.stopPropagation()}>
                        <div className="form-inline">
                            
                            <div className="form-group mr-1">
                                <div className={(hBold ? "highlight " : "") + "item-container"} onMouseDown={(e) => this.setBold()}>
                                    <img className="remove-icon"  src={bold} alt="Tučné písmo" title="Tučné písmo" />
                                </div>
                            </div>
                            <div className="form-group mr-1">
                                <div className={(hItalic ? "highlight " : "") + "item-container"} onMouseDown={(e) => this.setItalic()}>
                                    <img className="remove-icon"  src={italic} alt="Kurzíva" title="Kurzíva" />
                                </div>
                            </div>
                            <div className="form-group mr-1">
                                <div className={(hLink ? "highlight " : "") + "item-container"} onMouseDown={() => { this.setSelectedText(); cm.showLinkSettings("list",lastIndexes)}}>
                                    <img className="remove-icon"  src={link} alt="Odkaz" title="Odkaz" />
                                </div>
                                {element.showLinkSettings &&
                                    <Fragment>
                                        <div className="settings" onClick={(e) => e.preventDefault()}>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text" htmlFor="inputGroupSelect02">URL:</label>
                                                    </div>
                                                    <input type="text" className="form-control" name="url" onChange={(e) => this.setLinkData(e)} />
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text" htmlFor="inputGroupSelect02">Otevřít v:</label>
                                                    </div>
                                                    <select value={element.target} className="form-control" name="target" onChange={(e) => this.setLinkData(e)}>
                                                        <option value="_blank"> novém okně </option>
                                                        <option value=""> stejnm okně </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-1 form-inline justify-content-end">
                                                {hLink &&
                                                    <div className="form-group mr-1">
                                                        <div className="item-container">
                                                            <img onMouseDown={(e) => this.setLink(e,lastIndexes,true)} className="remove-icon"  src={remove} alt="Odstranit odkaz" title="Odstranit odkaz" />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="form-group">
                                                <button onMouseDown={(e) => this.setLink(e,lastIndexes,false)}>Vložit</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cm-closing-overlay" onClick={(e) => cm.showLinkSettings("list",lastIndexes)}></div>
                                    </Fragment>
                                }
                            </div>

                            <div className="form-group mr-1">
                                <div className="item-container" onClick={() => cm.showSettings("list",lastIndexes)}>
                                    <img className="remove-icon" src={settings} alt="Nastavení modulu" title="Nastavení modulu" />
                                </div>
                                {element.showSettings &&
                                    <Fragment>
                                        <div className="settings">
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text" htmlFor="inputGroupSelect02">Typ:</label>
                                                    </div>
                                                    <select value={element.type} className="form-control" name="type" onChange={(e) => cm.setElementData(e,lastIndexes,"list")}>
                                                        <option value="ul"> Odrážky </option>
                                                        <option value="ol"> Čísla </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text">Třída:</label>
                                                    </div>
                                                    <input type="text" value={element.className} className="form-control" name="className" onChange={(e) => cm.setElementData(e,lastIndexes,"list")}  />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cm-closing-overlay" onClick={() => cm.showSettings("list",lastIndexes)}></div>
                                    </Fragment>
                                }
                            </div>
                            {parentIndexes != "" &&
                                <div className="form-group mr-1">
                                    <div className="item-container" onClick={(e) => cm.selectElement(e,"column",parentIndexes)}>
                                        <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                    </div>
                                </div>
                            }
                            <div className="form-group">
                                <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                    <img className="remove-icon"  src={remove} alt="Odstranit odstavec" title="Odstranit odstavec" />
                                </div>
                            </div>
                        </div>  
                    </div>
                }

                <div className="cm-headline-content cm-list-content" onClick={(e) => {e.stopPropagation();!element.showMenu && cm.selectElement(e,"list",lastIndexes)}}>
                    
                    
                    {React.createElement(element.type, {className:element.className},[...element.listItems.map((item, index) => {

                        return <li onKeyDown={(e) => this.handleKeyPressLi(e)}><ContentEditable key={index} onFocus={() => {console.log("focus: " + index);this.setState({focusIndex:index})}} innerRef={this.inputRef[index]} onClick={(e) => {this.tf.highlightControls(e,iframeNode)}} tagName={"p"} className={"cm-list"} html={item.text[lang]} disabled={false} name="text" onPaste={(e) => this.onPaste(e)} onChange={(evt) => {if(item.text[lang] != evt.target.value){cm.setElementData(evt,lastIndexes,"list",null,null,true,index)}}} /></li>;

                    })])}

 
                </div>
                {showHelpers && <div className={"cm-border" + (element.showMenu ? " cm-selected" : "")}></div>}
                {showHelpers && 
                    <div className="cm-dragable-item" {...this.props.handle}>
                        <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                            <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                        </div>
                    </div>
                }

            </div>
        );

    }
}

export default ParagraphElement;