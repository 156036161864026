import React,{ Component,Fragment } from 'react';
import remove from '../../../Public/Images/remove.svg';
import settings from '../../../Public/Images/settings.svg';
import parent from '../../../Public/Images/parent.svg';
import move from '../../../Public/Images/move.svg';


class NewsletterElement extends Component{

    constructor(props){
        super(props);
    }

    render(){

        const {element,showHelpers,cm,lastIndexes} = this.props;
        var parentIndexes = "";
        
        var indexes = lastIndexes.split("-");
        if(indexes.length > 2){
            indexes.pop();
            parentIndexes = indexes.join("-");
        }

        return (
            <div className="cm-headline">

                {showHelpers && element.showMenu &&
                    <div className="cm-menu" onClick={(e) => e.stopPropagation()}>
                        <div className="form-inline">
                            
                            {parentIndexes != "" &&
                                <div className="form-group mr-1">
                                    <div className="item-container" onClick={(e) => cm.selectElement(e,"column",parentIndexes)}>
                                        <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                    </div>
                                </div>
                            }
                            <div className="form-group">
                                <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                    <img className="remove-icon"  src={remove} alt="Odstranit newsletter" title="Odstranit newsletter" />
                                </div>
                            </div>
                        </div>  
                    </div>
                }

                <div className="cm-headline-content" onClick={(e) => {e.stopPropagation(); !element.showMenu && cm.selectElement(e,"newsletter",lastIndexes)}}>
                    <div className="cm-content-padding text-center">
                        <div><input className="form-control" type="text" placeholder="Email" /></div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="newsletter" value="1" />
                            <label className="form-check-label" htmlFor="newsletter">Souhlasím s Obchodními podmínkami a správou osobních údajů</label>
                        </div>
                        <div className="text-center">
                            <button className="btn btn-primary">Odeslat</button>
                        </div>
                    </div>
                </div>
                {showHelpers && <div className={"cm-border" + (element.showMenu ? " cm-selected" : "")}></div>}
                {showHelpers && 
                    <div className="cm-dragable-item" {...this.props.handle}>
                        <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                            <img className="move-icon"  src={move} alt="Přesunout element" title="Přesunout element" />
                        </div>
                    </div>
                }

            </div>
        );

    }
}

export default NewsletterElement;