/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from "graphql-tag";

/**
 * add/edit rankingPoint
 */
export const ADD_EDIT_GLOBAL_DISCIPLINE = gql`
    mutation AddEditRankingPoint($addEditData: AddEditRankingPointDataInput) {
        addEditRankingPoint(addEditData: $addEditData) {
            rank
            points
        }
    }
`;

/**
 *  Data federace
 */

export const GET_GLOBAL_DISCIPLINE = gql`
    query RankingPoint($rankingPointID: ID, $lang: String) {
        rankingPoint(rankingPointID: $rankingPointID) {
            rankingPointID
            rank
            points
            sportID
        }
        sports(filters: { lang: $lang }) {
            sportID
            title
        }
    }
`;

/**
 *  Seznam veškerých federací
 */
export const GET_GLOBAL_DISCIPLINES = gql`
    query RankingPoints($filters: RankingPointsFilters, $lang: String) {
        allRankingPoints(filters: $filters) {
            rankingPointID
            rank
            points
            sportID
            sport(lang: $lang) {
                title
            }
        }
        rankingPointsCount(filters: $filters)
        sports(filters: { lang: $lang }) {
            sportID
            title
        }
    }
`;

/**
 * Smazání federací.
 */
export const DELETE_GLOBAL_DISCIPLINES = gql`
    mutation deleteRankingPoints($rankingPointIDs: ID!) {
        deleteRankingPoints(rankingPointIDs: $rankingPointIDs)
    }
`;
