/*
    Přidání štítku
*/
import React, { useState } from "react";
import Modal from "react-modal";
import PrimaryData from "./PrimaryData";
import Disciplines from "../../Discipline/Components/Disciplines";
import Notifications from "../../Competition/Components/Notifications";
import DisciplineDetail from "../../Discipline/Components/DisciplineDetail";
import translate from "../../Translations/index";
import { GET_COMPETITION } from "../Queries/competition";
import Loading from "../../../GlobalComponents/Loading";
import { Switch, Route, NavLink, withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";

const CompetitionDetail = (props) => {
    const {
        loading: getLoading,
        error: getError,
        data: competitionData,
    } = useQuery(GET_COMPETITION, {
        variables: { hashID: props.match.params.competitionHashID, lang: translate.locale },
        fetchPolicy: "cache-and-network",
    });

    return (
        <div id="settings" className="whole-container">
            {!getLoading ? (
                <div className="row">
                    <div className="col-sm one">
                        <h2 className="mb-4 mt-2">
                            <span className="bold">{competitionData.competition.title}</span>
                        </h2>

                        <ul className="nav nav-tabs mt-3 mb-0 border-bottom-0" role="tablist">
                            <li className="nav-item mr-2">
                                <NavLink
                                    activeClassName="active"
                                    className="nav-link"
                                    strict
                                    to={"/competition/" + props.match.params.competitionHashID + "/primary-data"}
                                >
                                    {translate.t("CompetitionPrimaryData")}
                                </NavLink>
                            </li>
                            <li className="nav-item mr-2">
                                <NavLink
                                    activeClassName="active"
                                    className="nav-link"
                                    strict
                                    to={"/competition/" + props.match.params.competitionHashID + "/disciplines"}
                                >
                                    {translate.t("Disciplines")}
                                </NavLink>
                            </li>
                            <li className="nav-item mr-2">
                                <NavLink
                                    activeClassName="active"
                                    className="nav-link"
                                    strict
                                    to={"/competition/" + props.match.params.competitionHashID + "/notifications"}
                                >
                                    {translate.t("Notifications")}
                                </NavLink>
                            </li>
                        </ul>
                        <div className="card main">
                            <Switch>
                                <Route exact path="/competition/:competitionHashID/primary-data">
                                    <div className="card-body">
                                        <PrimaryData inModal={false} selectedCompetitionHashID={props.match.params.competitionHashID} />
                                    </div>
                                </Route>
                                <Route exact path="/competition/:competitionHashID/disciplines">
                                    <div className="card-body">
                                        <Disciplines sportID={competitionData.competition.sportID} />
                                    </div>
                                </Route>
                                <Route exact path="/competition/:competitionHashID/notifications">
                                    <div className="card-body">
                                        <Notifications />
                                    </div>
                                </Route>
                                <Route path="/competition/:competitionHashID/discipline/:disciplineHashID">
                                    <div className="card-body p-0">
                                        <DisciplineDetail sportID={competitionData.competition.sportID} />
                                    </div>
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default withRouter(CompetitionDetail);
