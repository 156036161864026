/*
    Přidání štítku
*/
import React, { Component, Fragment, useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import { ADD_EDIT_DISCIPLINE, GET_DISCIPLINE } from "../Queries/discipline";
import DisciplineLib from "../Library/discipline";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import { withRouter } from "react-router-dom";

const AddEditDiscipline = (props) => {
    const client = useApolloClient();
    const disciplineLib = new DisciplineLib(props);
    const [allAgeCategories, setAllAgeCategories] = useState([]);
    const [unlimitedSportUnitCount, setUnlimitedSportUnitCount] = useState(1);
    const [unlimitedRegistrationCount, setUnlimitedRegistrationCount] = useState(1);

    const [formData, setFormData] = useState({
        hashID: props.selectedDisciplineHashID,
        competitionHashID: props.selectedCompetitionHashID,
        globalDisciplineID: 0,
        athleteCountInTeam: 1,
        ageCategoryID: 0,
        sportUnitCount: 0, //kolik je sportovních jednotek (střelnic, stolů, dráh, atd) - podle toho se rozpočítávají jednotlivé rozběhy
        maxTeamRegistrationCount: 0, //kolik maximálně atletů/týmů se může do disciplíny registrovat
        advancingToFinalCount: 0, //kolik atletů/týmů postupuje do finále
        heatsEnabled: 0, //jestli jsou zapnuté rozběhy
        performanceCategoryIDs: [],
        lang: translate.locale,
    });

    const {
        loading: getLoading,
        error: getError,
        data: disciplineData,
    } = useQuery(GET_DISCIPLINE, {
        variables: { hashID: formData.hashID, lang: translate.locale, sportID: props.sportID },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            if (formData.hashID != "") {
                setFormData({
                    ...formData,
                    globalDisciplineID: data.discipline.globalDisciplineID,
                    athleteCountInTeam: data.discipline.athleteCountInTeam,
                    ageCategoryID: data.discipline.ageCategoryID,
                    sportUnitCount: data.discipline.sportUnitCount,
                    maxTeamRegistrationCount: data.discipline.maxTeamRegistrationCount,
                    performanceCategoryIDs: data.discipline.performanceCategories.map((item) => item.performanceCategoryID),
                    advancingToFinalCount: data.discipline.advancingToFinalCount,
                    heatsEnabled: data.discipline.heatsEnabled,
                });
                if (data.discipline.maxTeamRegistrationCount > 0) {
                    setUnlimitedRegistrationCount(0);
                }
            }
            setAllAgeCategories(data.allAgeCategorys);
        },
    });

    const [addDiscipline, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_DISCIPLINE, {
        onCompleted(data) {
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));
            if (!formData.hashID) {
                props.history.push(
                    "/competition/" + data.addEditDiscipline.competitionHashID + "/discipline/" + data.addEditDiscipline.hashID + "/primary-data"
                );
            }

            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const changeSelectedPerformanceCategories = (item) => {
        if (formData.performanceCategoryIDs.includes(item.performanceCategoryID)) {
            setFormData({
                ...formData,
                performanceCategoryIDs: [...formData.performanceCategoryIDs.filter((filterItem) => filterItem != item.performanceCategoryID)],
            });
        } else {
            formData.performanceCategoryIDs.push(item.performanceCategoryID);
            setFormData({ ...formData, performanceCategoryIDs: [...formData.performanceCategoryIDs] });
        }
    };
    const addDisciplineData = () => {
        if (
            !!formData.globalDisciplineID &&
            (formData.heatsEnabled == 0 || (formData.heatsEnabled == 1 && !!parseInt(formData.sportUnitCount) && !!parseInt(formData.advancingToFinalCount)))
        ) {
            let dataToSend = {
                ...formData,
                sportUnitCount: !!parseInt(formData.sportUnitCount) ? parseInt(formData.sportUnitCount) : 0,
                maxTeamRegistrationCount: !!parseInt(formData.maxTeamRegistrationCount) ? parseInt(formData.maxTeamRegistrationCount) : 0,
                advancingToFinalCount: !!parseInt(formData.advancingToFinalCount) ? parseInt(formData.advancingToFinalCount) : 0,
                heatsEnabled: parseInt(formData.heatsEnabled),
            };
            addDiscipline({
                variables: {
                    addEditData: dataToSend,
                },
            });
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("NotFilledRequiredFields"), "danger");
        }
    };

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }
    console.log(parseInt(formData.maxTeamRegistrationCount));
    return (
        <Fragment>
            <div className={props.inModal ? "modal-body " : ""}>
                {error ? (
                    <Error text={error} />
                ) : (
                    <div>
                        {!getLoading && !addLoading ? (
                            <Fragment>
                                {!props.inModal && <div className="discipline-title">{translate.t("DisciplinePrimaryData")}</div>}
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="input-label">*{translate.t("Discipline")}</label>
                                            <div className="form-group">
                                                <select
                                                    value={formData.globalDisciplineID}
                                                    className="form-control"
                                                    name="globalDisciplineID"
                                                    onChange={(e) => disciplineLib.formHandle(e, formData, setFormData)}
                                                >
                                                    <option value={0}>-- {translate.t("ChooseDiscipline")} --</option>
                                                    {disciplineData?.allGlobalDisciplines.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.globalDisciplineID}>
                                                                {item.title}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="form-group">
                                            <label className="input-label">*{translate.t("AthleteCountInTeam")}</label>
                                            <div className="form-group">
                                                <select
                                                    value={formData.athleteCountInTeam}
                                                    className="form-control"
                                                    name="athleteCountInTeam"
                                                    onChange={(e) => disciplineLib.formHandle(e, formData, setFormData)}
                                                >
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="form-group">
                                            <label className="input-label">{translate.t("AgeCategory")}</label>
                                            <div className="form-group">
                                                <select
                                                    value={formData.ageCategoryID}
                                                    className="form-control"
                                                    name="ageCategoryID"
                                                    onChange={(e) => disciplineLib.formHandle(e, formData, setFormData)}
                                                >
                                                    <option value={0}>{translate.t("UnlimitedByAge")}</option>
                                                    {allAgeCategories &&
                                                        allAgeCategories.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.ageCategoryID}>
                                                                    {item.title}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-10">
                                                <div className="form-group">
                                                    <label className="input-label">
                                                        {translate.t("MaxTeamRegistrationCountTitle")}{" "}
                                                        <i
                                                            className="las la-question-circle tooltip-icon"
                                                            data-tooltip-id="simple-600"
                                                            data-tooltip-html={translate.t("Tootlip-MaxRegisteredTeamsInDiscipline")}
                                                        ></i>
                                                    </label>
                                                    <div className="form-group">
                                                        <select
                                                            value={unlimitedRegistrationCount}
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                setFormData({ ...formData, maxTeamRegistrationCount: 0 });
                                                                setUnlimitedRegistrationCount(e.target.value);
                                                            }}
                                                        >
                                                            <option value={1}>{translate.t("UnlimitedRegistrationCount")}</option>
                                                            <option value={0}>{translate.t("SetUnitCount")}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2 pl-0">
                                                <div className="form-group">
                                                    <label className="input-label">&nbsp;</label>
                                                    <div className="form-group">
                                                        <input
                                                            className={`form-control ${unlimitedRegistrationCount != "0" ? "disabled" : ""}`}
                                                            name="maxTeamRegistrationCount"
                                                            type="number"
                                                            inputmode="numeric"
                                                            step={1}
                                                            value={formData.maxTeamRegistrationCount}
                                                            onChange={(e) => disciplineLib.formHandle(e, formData, setFormData)}
                                                            disabled={unlimitedRegistrationCount != "0"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="card mb-3">
                                            <div className="card-header d-flex align-items-center fs14">
                                                <strong>
                                                    {translate.t("HeatsSettings")}{" "}
                                                    <i
                                                        className="las la-question-circle tooltip-icon"
                                                        data-tooltip-id="simple-600"
                                                        data-tooltip-html={translate.t("Tootlip-HeatsSettings")}
                                                    ></i>
                                                </strong>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="input-label">{translate.t("EnableHeats")}</label>
                                                            <div className="form-group">
                                                                <select
                                                                    value={formData.heatsEnabled}
                                                                    className="form-control"
                                                                    onChange={(e) => {
                                                                        setFormData({ ...formData, heatsEnabled: e.target.value });
                                                                    }}
                                                                >
                                                                    <option value={1}>{translate.t("Yes")}</option>
                                                                    <option value={0}>{translate.t("No")}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {formData.heatsEnabled == 1 && (
                                                        <div className="col-4 pl-0">
                                                            <div className="form-group">
                                                                <label className="input-label">
                                                                    *{translate.t("SportUnitCountTitle")}{" "}
                                                                    <i
                                                                        className="las la-question-circle tooltip-icon"
                                                                        data-tooltip-id="simple-600"
                                                                        data-tooltip-html={translate.t("Tootlip-SportUnitCount")}
                                                                    ></i>
                                                                </label>
                                                                <div className="form-group">
                                                                    <input
                                                                        className={`form-control`}
                                                                        name="sportUnitCount"
                                                                        type="number"
                                                                        inputmode="numeric"
                                                                        step={1}
                                                                        value={formData.sportUnitCount}
                                                                        onChange={(e) => disciplineLib.formHandle(e, formData, setFormData)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {formData.heatsEnabled == 1 && (
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <label className="input-label">*{translate.t("TeamCountAdvancingToFinal")}</label>
                                                                <div className="form-group">
                                                                    <input
                                                                        className={`form-control`}
                                                                        name="advancingToFinalCount"
                                                                        type="number"
                                                                        inputmode="numeric"
                                                                        step={1}
                                                                        value={formData.advancingToFinalCount}
                                                                        onChange={(e) => disciplineLib.formHandle(e, formData, setFormData)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="card mb-3">
                                            <div className="card-header d-flex align-items-center fs14">
                                                <strong>{translate.t("PerformanceCategories")}</strong>
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap">
                                                    {disciplineData.allPerformanceCategories.map((item, index) => (
                                                        <div className="custom-control custom-checkbox custom-control-inline" key={index}>
                                                            <input
                                                                type="checkbox"
                                                                id={`pcCheckbox-${item.performanceCategoryID}`}
                                                                name="customRadioInline"
                                                                className="custom-control-input"
                                                                value={item.performanceCategoryID}
                                                                checked={formData.performanceCategoryIDs.includes(item.performanceCategoryID)}
                                                                onChange={() => changeSelectedPerformanceCategories(item)}
                                                            />
                                                            <label className="custom-control-label" htmlFor={`pcCheckbox-${item.performanceCategoryID}`}>
                                                                {item.title}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        ) : (
                            <Loading />
                        )}
                    </div>
                )}
            </div>
            <div className={"d-flex " + (props.inModal ? "modal-footer" : "")}>
                <button competition="button" className={"btn btn-primary " + (!props.inModal ? "ml-auto" : "flex-fill")} onClick={() => addDisciplineData()}>
                    {formData.hashID && formData.hashID != "" ? translate.t("Edit") : translate.t("Add")}
                </button>
                {props.inModal && (
                    <button competition="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                        {translate.t("Close")}
                    </button>
                )}
            </div>
        </Fragment>
    );
};

export default withRouter(AddEditDiscipline);
