import React, { Component, Fragment } from "react";
import Modal from "react-modal";
import {
    GET_TINYMCE_IMAGES,
    DELETE_TINYMCE_IMAGES,
    UPLOAD_TINYMCE_IMAGES,
    GET_TINYMCE_FILES,
    DELETE_TINYMCE_FILES,
    UPLOAD_TINYMCE_FILES,
} from "../Queries/index";
import { SERVER_URL, TINYMCE_IMAGE_URL, TINYMCE_FILE_URL } from "../../../Config/index";
import Notification from "../../../Library/notification";
import TinyMCELib from "../Library/tinyMCELib";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Helper from "../../../Library/helper";
import { Query, Mutation } from "@apollo/client/react/components";

Modal.setAppElement("#root");

const INITIAL_STATE = {
    selectedFiles: [],
    showDeleteInfo: false,
    deleteTinyMCEData: null,
    imagePath: "",
    filename: "",
    imageID: 0,
};

class TinyMCEFileManager extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.delTinyMCEFile = this.delTinyMCEFile.bind(this);
    }

    componentDidMount() {
        this.tinyMCELib = new TinyMCELib(this, INITIAL_STATE);
    }

    delTinyMCEFile(action, fileTye) {
        this.tinyMCELib.delTinyMCEFile(action, fileTye);
    }

    render() {
        const { showDeleteInfo } = this.state;
        const { tinyMCECallBack, tinyMCEFileType } = this.props;

        return (
            <Modal className="Modal__Bootstrap modal-dialog modal-lg" closeTimeoutMS={150} isOpen={true} onRequestClose={() => this.props.callback(false)}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Souborový manager | {tinyMCEFileType == "image" ? "Fotky" : "Soubory"}</h4>
                        <button type="button" className="close" onClick={() => this.props.callback(false)}>
                            <span aria-hidden="true">x</span>
                            <span className="sr-only">Close</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Query query={tinyMCEFileType == "image" ? GET_TINYMCE_IMAGES : GET_TINYMCE_FILES}>
                            {({ data, loading, error }) => {
                                if (loading) return <Loading />;
                                if (error) return <Error text={error} />;

                                return (
                                    <Mutation
                                        mutation={tinyMCEFileType == "image" ? DELETE_TINYMCE_IMAGES : DELETE_TINYMCE_FILES}
                                        update={async (cache, response) => {
                                            let tinyMCELib = new TinyMCELib();
                                            if (tinyMCEFileType == "image") tinyMCELib.updateUploadedImagesAfterDelete(cache, response);
                                            if (tinyMCEFileType == "file") tinyMCELib.updateUploadedFilesAfterDelete(cache, response);

                                            let notify = new Notification();
                                            notify.setNotification("Úspěšně smazáno");
                                        }}
                                        onError={(error) => {
                                            const helper = new Helper();
                                            let notify = new Notification();
                                            notify.setNotification(helper.getApolloErrorText(error), "danger");
                                        }}
                                    >
                                        {(deleteTinyMCEData) => (
                                            <Fragment>
                                                <Mutation
                                                    mutation={tinyMCEFileType == "image" ? UPLOAD_TINYMCE_IMAGES : UPLOAD_TINYMCE_FILES}
                                                    update={async (cache, response) => {
                                                        let tinyMCELib = new TinyMCELib();
                                                        if (tinyMCEFileType == "image") tinyMCELib.updateUploadedImages(cache, response);
                                                        if (tinyMCEFileType == "file") tinyMCELib.updateUploadedFiles(cache, response);

                                                        let notify = new Notification();
                                                        notify.setNotification("Úspěšně nahráno");
                                                    }}
                                                    onError={(error) => {
                                                        const helper = new Helper();
                                                        let notify = new Notification();
                                                        notify.setNotification(helper.getApolloErrorText(error), "danger");
                                                    }}
                                                >
                                                    {(uploadTinyMCEData) => (
                                                        <div className="form-group input-group">
                                                            <div className="custom-file">
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    id="customFile"
                                                                    required
                                                                    multiple
                                                                    onChange={({ target: { validity, files: files } }) => {
                                                                        validity.valid && uploadTinyMCEData({ variables: { files: files } });
                                                                    }}
                                                                />
                                                                <label className="custom-file-label" htmlFor="customFile">
                                                                    Vyberte {tinyMCEFileType == "image" ? "fotky" : "soubory"}
                                                                </label>
                                                            </div>
                                                            <div className="input-group-append">
                                                                <button
                                                                    className="btn btn-danger btn-delete-files"
                                                                    type="button"
                                                                    onClick={() => this.tinyMCELib.deleteTinyMCEData(deleteTinyMCEData)}
                                                                >
                                                                    Odstranit označené {tinyMCEFileType == "image" ? "fotky" : "soubory"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Mutation>

                                                <div className="row">
                                                    {data.allTinyMCEImages &&
                                                        data.allTinyMCEImages.map((item, index) => {
                                                            return (
                                                                <div key={index} className="col-3 form-group">
                                                                    <div className="cover-item file-list-item">
                                                                        <div className="menu-item">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="delete"
                                                                                onChange={(e) => this.tinyMCELib.setDeleteFile(e, item.imageID)}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group text-center">
                                                                            <img
                                                                                className="cursor-pointer"
                                                                                onClick={() =>
                                                                                    this.tinyMCELib.selectFile(
                                                                                        tinyMCECallBack,
                                                                                        SERVER_URL + "/" + TINYMCE_IMAGE_URL + "/velka_" + item.name
                                                                                    )
                                                                                }
                                                                                src={SERVER_URL + "/" + TINYMCE_IMAGE_URL + "/mala_" + item.name}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}

                                                    {data.allTinyMCEFiles &&
                                                        data.allTinyMCEFiles.map((item, index) => {
                                                            return (
                                                                <div key={index} className="col-3 form-group">
                                                                    <div className="cover-item file-list-item">
                                                                        <div className="menu-item">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="delete"
                                                                                onChange={(e) => this.tinyMCELib.setDeleteFile(e, item.fileID)}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group text-center">
                                                                            <img
                                                                                className="cursor-pointer icon"
                                                                                onClick={() =>
                                                                                    this.tinyMCELib.selectFile(
                                                                                        tinyMCECallBack,
                                                                                        SERVER_URL + "/" + TINYMCE_FILE_URL + "/" + item.name
                                                                                    )
                                                                                }
                                                                                src={this.tinyMCELib.getIcon(item.type)}
                                                                            />
                                                                            <p className="fs-14">{item.name}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>

                                                {showDeleteInfo && (
                                                    <ModalNotification
                                                        yesNo={true}
                                                        text={"Opravdu chcete vymazat vybrané " + (tinyMCEFileType == "image" ? "obrázky" : "soubory") + " ?"}
                                                        callback={(action) => this.delTinyMCEFile(action, tinyMCEFileType)}
                                                    />
                                                )}
                                            </Fragment>
                                        )}
                                    </Mutation>
                                );
                            }}
                        </Query>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={() => this.props.callback(false)}>
                            Zavřít
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default TinyMCEFileManager;

/*
var data = await this.props.client.query({ 
	query: UPLOAD_TINYMCE_IMAGE,
	errorPolicy:"all",
	variables:{file:blobInfo.blob()}, 
	fetchPolicy: 'network-only'
});

if(data.errors){

	failure(data.errors[0].message);
	this.setState({err:data.errors[0].message});

}else if(data.data.uploadTinyMceImage){
	success(SERVER_URL + "/" + TINYMCE_IMAGE_URL + "/velka_" + data.data.uploadTinyMceImage.name);
}*/
