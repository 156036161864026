/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from "graphql-tag";

/**
 * add/edit heat
 */
export const ADD_EDIT_HEAT = gql`
    mutation AddEditHeat($addEditData: AddEditHeatDataInput) {
        addEditHeat(addEditData: $addEditData) {
            heatID
            hashID
            heatOrder
        }
    }
`;

/**
 *  Data federace
 */
export const GET_HEAT = gql`
    query Heat($heatID: ID) {
        heat(heatID: $heatID) {
            heatID
            hashID
            heatOrder
        }
    }
`;

/**
 *  Seznam veškerých federací
 */
export const GET_HEATS = gql`
    query Heats($disciplineHashID: ID, $filters: HeatsFilters) {
        heats(disciplineHashID: $disciplineHashID, filters: $filters) {
            heatID
            hashID
            heatOrder
            teamCount
        }
        discipline(hashID: $disciplineHashID) {
            disciplineID
        }
    }
`;

/**
 * Smazání federací.
 */
export const DELETE_HEATS = gql`
    mutation deleteHeats($heatIDs: ID!) {
        deleteHeats(heatIDs: $heatIDs)
    }
`;

/**
 * Vygenerování rozběhů.
 */
export const GENERATE_HEATS = gql`
    mutation generateHeats($disciplineHashID: ID, $lang: String) {
        generateHeats(disciplineHashID: $disciplineHashID, lang: $lang)
    }
`;

/**
 * Správa týmů v rozběhu
 */

export const ADD_EDIT_HEAT_TEAM = gql`
    mutation AddEditHeatTeam($addEditData: AddEditHeatTeamDataInput) {
        addEditHeatTeam(addEditData: $addEditData) {
            heatTeamID
        }
    }
`;
export const GET_HEAT_TEAMS = gql`
    query HeatTeams($heatID: ID) {
        heatTeams(heatID: $heatID) {
            heatTeamID
            teamID
            title
            rank
            time
        }
    }
`;
export const GET_HEAT_TEAM = gql`
    query HeatTeam($heatTeamID: ID, $disciplineID: ID, $heatID: ID, $heatTeamIdToInclude: ID) {
        heatTeam(heatTeamID: $heatTeamID) {
            heatTeamID
            teamID
            title
            rank
            time
        }
        heatUnassignedTeams(disciplineID: $disciplineID, heatTeamIdToInclude: $heatTeamIdToInclude) {
            teamID
            title
        }
        heatTeamsCount(heatID: $heatID)
    }
`;
export const DELETE_HEAT_TEAMS = gql`
    mutation deleteHeatTeams($heatTeamIDs: ID!) {
        deleteHeatTeams(heatTeamIDs: $heatTeamIDs)
    }
`;
