/**
 *  komponenta pro štítky pro produkty
 */
import React, { Fragment, useState } from "react";
import AddEditHeatTeam from "./AddEditHeatTeam";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation } from "@apollo/client";
import { GET_HEAT_TEAMS, DELETE_HEAT_TEAMS } from "../Queries/heat";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";

const HeatTeamList = (props) => {
    const [selectedHeatTeams, setSelectedHeatTeams] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedHeatTeamID, setSelectedHeatTeamID] = useState("");

    const {
        loading,
        error: getError,
        data: heatTeamListData,
        fetchMore,
        refetch,
    } = useQuery(GET_HEAT_TEAMS, {
        variables: {
            heatID: props.heatID,
        },
        fetchPolicy: "cache-and-network",
    });

    const [deleteHeatTeam, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_HEAT_TEAMS, {
        update(cache, response) {
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
            refetch();
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(helper.getApolloErrorText(err), "danger");
        },
    });

    const openCloseModal = (open, heatTeamID) => {
        setSelectedHeatTeamID(heatTeamID);
        setShowAdd(open);
    };

    /**
     * smazání štítků
     * @param {*} deleteHeatTeams funkce, která posílá data na server
     * @param {*} heatTeamID ID heatTeamu
     */

    const initiateDeleteHeatTeam = (e, heatTeamID) => {
        var checked = e.target.checked;
        var arr = [...selectedHeatTeams];

        if (!checked) {
            for (let i in selectedHeatTeams) {
                if (selectedHeatTeams[i] == heatTeamID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [heatTeamID, ...arr];
        }

        setSelectedHeatTeams(arr);
    };

    const delHeatTeam = (action) => {
        if (action) {
            let heatTeamIDs = selectedHeatTeams.join(",");

            deleteHeatTeam({
                variables: {
                    heatTeamIDs: heatTeamIDs,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedHeatTeams.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <div>
            <div className="row">
                <div className="col-sm one">
                    <div className="card main">
                        <div className="card-header d-flex align-items-center">
                            {translate.t("HeatTeamList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, "")}>
                                {translate.t("Add")}
                            </button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                                {translate.t("DeleteSelected")}
                            </button>
                        </div>
                        <div className="card-body">
                            {!error ? (
                                <div>
                                    <div className="data-list">
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("Name")}</div>
                                                <div className="text-center">{translate.t("HeatRank")}</div>
                                                <div className="text-center">{translate.t("HeatResultTime")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {heatTeamListData?.heatTeams?.map((item, index) => {
                                                var checked = false;
                                                for (let i in selectedHeatTeams) {
                                                    if (selectedHeatTeams[i] == item.heatTeamID) checked = true;
                                                }

                                                return (
                                                    <div key={index} className="data-list-item-content">
                                                        <div>{item.title}</div>
                                                        <div className="text-center">
                                                            {item.rank == 0 ? <span class="badge badge-danger">{translate.t("NotRankedYet")}</span> : item.rank}
                                                        </div>
                                                        <div className="text-center">{item.time}</div>
                                                        <div className="text-right">
                                                            <img onClick={() => openCloseModal(true, item.heatTeamID)} className="edit-icon" src={edit} />
                                                            <input
                                                                className="delete-checked"
                                                                type="checkbox"
                                                                name="deleteHeatTeam[]"
                                                                checked={checked}
                                                                onChange={(e) => initiateDeleteHeatTeam(e, item.heatTeamID)}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Fragment>
                                    </div>
                                    {!loading ? (
                                        <Fragment>
                                            {!loading && <>{!heatTeamListData?.heatTeams?.length && <NoItems text={translate.t("NoItemsAddedYet")} />}</>}
                                        </Fragment>
                                    ) : (
                                        <Loading />
                                    )}
                                </div>
                            ) : (
                                <Fragment>{error && <Error text={error} />}</Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAdd && (
                <AddEditHeatTeam
                    openCloseModal={openCloseModal}
                    heatTeamID={selectedHeatTeamID}
                    heatID={props.heatID}
                    disciplineID={props.disciplineID}
                    updated={() => refetch()}
                />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delHeatTeam} />}
        </div>
    );
};

export default HeatTeamList;
