/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from "graphql-tag";

/**
 * add/edit ageCategory
 */
export const ADD_EDIT_AGECATEGORY = gql`
    mutation AddEditAgeCategory($addEditData: AddEditAgeCategoryDataInput) {
        addEditAgeCategory(addEditData: $addEditData) {
            ageCategoryID
            hashID
            sportID
            title
            ageLimitFrom
            ageLimitTo
        }
    }
`;

/**
 *  AgeCategory data
 */

export const GET_AGECATEGORY = gql`
    query AgeCategoryWithLangs($ageCategoryID: ID!, $lang: String) {
        ageCategoryWithLangs(ageCategoryID: $ageCategoryID) {
            ageCategoryID
            sportID
            ageLimitFrom
            ageLimitTo
            langs {
                lang
                title
                description
            }
        }
        allLanguageMutations(onlyEnabled: true) {
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        sports(filters: { lang: $lang }) {
            sportID
            title
        }
    }
`;

/**
 *  Seznam veškerých ageCategoryů
 */
export const GET_AGECATEGORYS = gql`
    query AllAgeCategorys($filters: AgeCategoryFilters, $lang: String) {
        allAgeCategorys(filters: $filters) {
            ageCategoryID
            hashID
            sportID
            title
            ageLimitFrom
            ageLimitTo
            sport(lang: $lang) {
                title
            }
        }
        ageCategoryListCount(filters: $filters)

        sports(filters: { lang: $lang }) {
            sportID
            title
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_AGECATEGORYS = gql`
    mutation deleteAgeCategorys($ageCategoryIDs: ID!) {
        deleteAgeCategorys(ageCategoryIDs: $ageCategoryIDs)
    }
`;
