/*
    Přidání štítku
*/
import React, { Fragment, useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import { ADD_EDIT_SPORT, GET_SPORT } from "../Queries/sport";
import SportLib from "../Library/sport";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import { Editor } from "@tinymce/tinymce-react";
import { getLangValue } from "../../../Library/helper";

const AddEditSport = (props) => {
    const client = useApolloClient();
    const sportLib = new SportLib(props);
    const helper = new Helper();
    const [selectedLang, setSelectedLang] = useState("cs");
    const [formData, setFormData] = useState({
        sportID: props.selectedID,
        lang: translate.locale,
        langs: [],
    });
    const [allLanguageMutations, setAllLanguageMutations] = useState([]);

    const {
        loading: getLoading,
        error: getError,
        data: sportData,
    } = useQuery(GET_SPORT, {
        variables: { sportID: formData.sportID },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            setAllLanguageMutations(data.allLanguageMutations);
            if (!!formData.sportID) {
                let langs = sportLib.completeLangsArray(helper.copyObjectsWithoutParam(data.sport.langs, "__typename"), data.allLanguageMutations);
                setFormData({ ...formData, langs: langs });
            } else {
                let langs = sportLib.completeLangsArray([], data.allLanguageMutations);
                setFormData({ ...formData, langs: langs });
            }
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(err.toString(), "danger");
        },
    });

    const [addSport, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_SPORT, {
        onCompleted(data) {
            setFormData({ ...formData, sportID: data.addEditSport.sportID });
            props.openCloseModal(false);
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));

            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(err.message ? err.message : err.toString(), "danger");
        },
    });

    const addSportData = () => {
        let dataToSend = {
            ...formData,
        };
        addSport({
            variables: {
                addEditData: dataToSend,
            },
        });
    };

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }
    console.log(formData);
    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-md sport-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{formData.sportID && formData.sportID != "" ? translate.t("EditSport") : translate.t("AddSport")}</h4>
                    {allLanguageMutations && allLanguageMutations.length > 1 && (
                        <div className="ml-auto">
                            <select className="form-control" name="selectedLang" onChange={(e) => setSelectedLang(e.target.value)}>
                                {allLanguageMutations &&
                                    allLanguageMutations.map((item, index) => (
                                        <option key={index} value={item.suffix}>
                                            {" "}
                                            {item.suffix}{" "}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    )}
                    <button type="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ? (
                        <Error text={error} />
                    ) : (
                        <div>
                            {!getLoading && !addLoading ? (
                                <Fragment>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="input-label">
                                                    {translate.t("SportTitle")}{" "}
                                                    {allLanguageMutations && allLanguageMutations.length > 1 && <Fragment>({selectedLang})</Fragment>}
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="title"
                                                        value={helper.getLangValue(formData, "title", selectedLang)}
                                                        onChange={(e) => helper.formLangHandle(e, formData, setFormData, selectedLang)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => addSportData()}>
                        {formData.sportID && formData.sportID != "" ? translate.t("Edit") : translate.t("Add")}
                    </button>
                    <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                        {translate.t("Close")}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AddEditSport;
