/*
    Přidání štítku
*/
import React, { Component, Fragment, useState } from "react";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import { ADD_EDIT_ATHLETE, GET_ATHLETE } from "../Queries/athlete";
import AthleteLib from "../Library/athlete";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import LinkSelector from "../../Content/Components/LinkSelector";
import translate from "../../Translations/index";
import refreshIcon from "../../../Public/Images/refresh.svg";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SERVER_URL, ATHLETE_IMAGE_URL, MAX_FILE_UPLOAD_SIZE } from "../../../Config";
import remove from "../../../Public/Images/remove.svg";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";

const AddEditAthlete = (props) => {
    const client = useApolloClient();
    const [fileInputKey, setFileInputKey] = useState(0);
    const athleteLib = new AthleteLib(props);
    const [formData, setFormData] = useState({
        selectedLang: "cs",
        athleteID: props.selectedAthleteID,
        name: "",
        surname: "",
        email: "",
        birthDate: null,
        phone: "",
        password: "",
        bibliography: "",
        internationalLicence: "",
        licenceNumber: "",
        sexID: 0,
        countryID: 0,
        clubID: 0,
        photo: "",
        photoURL: "",
        roleID: 0,
        lang: translate.locale,
    });
    const [allLanguageMutations, setAllLanguageMutations] = useState([]);

    const {
        loading: getLoading,
        error: getError,
        data: athleteData,
    } = useQuery(GET_ATHLETE, {
        variables: { athleteID: formData.athleteID, lang: translate.locale },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            setAllLanguageMutations(data.allLanguageMutations);
            if (formData.athleteID != 0) {
                setFormData({
                    ...formData,
                    name: data.athlete.name,
                    surname: data.athlete.surname,
                    email: data.athlete.email,
                    birthDate: moment(data.athlete.birthDate).toDate(),
                    phone: data.athlete.phone,
                    bibliography: data.athlete.bibliography,
                    photoURL: data.athlete.photo ? SERVER_URL + "/" + ATHLETE_IMAGE_URL + "/stredni_" + data.athlete.photo : "",
                    roleID: data.athlete.roleID,
                    isCurrencyExchange: data.athlete.isCurrencyExchange,
                    sexID: data.athlete.sexID,
                    internationalLicence: data.athlete.internationalLicence,
                    licenceNumber: data.athlete.licenceNumber,
                    countryID: data.athlete.countryID,
                    clubID: data.athlete.clubID,
                });
            } else {
                generateNewPwd();
            }
        },
    });

    const [addAthlete, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_ATHLETE, {
        onCompleted(data) {
            setFormData({ ...formData, athleteID: data.addEditAthlete.athleteID });
            props.openCloseModal(false);
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));

            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const addAthleteData = () => {
        const helper = new Helper();
        let notify = new Notification();
        if (
            !!formData.name &&
            !!formData.surname &&
            !!formData.email &&
            !!formData.internationalLicence &&
            formData.birthDate != null &&
            formData.sexID != 0 &&
            formData.countryID != 0
        ) {
            if (helper.validateEmail(formData.email)) {
                addAthlete({
                    variables: {
                        addEditData: {
                            athleteID: formData.athleteID,
                            name: formData.name,
                            surname: formData.surname,
                            sexID: formData.sexID,
                            internationalLicence: formData.internationalLicence,
                            licenceNumber: formData.licenceNumber,
                            countryID: formData.countryID,
                            clubID: formData.clubID,
                            email: formData.email,
                            phone: formData.phone,
                            photo: formData.photo,
                            photoURL: formData.photoURL,
                            bibliography: formData.bibliography,
                            birthDate: moment(formData.birthDate).format("YYYY-MM-DD"),
                            password: formData.password,
                            lang: translate.locale,
                        },
                    },
                });
            } else {
                notify.setNotification(translate.t("IncorrectEmailForm"), "danger");
            }
        } else {
            notify.setNotification(translate.t("NotFilledRequiredFields"), "danger");
        }
    };

    const generateNewPwd = () => {
        const helper = new Helper();
        setFormData({ ...formData, password: helper.makeid(6) });
    };

    const openImage = (e) => {
        var file = e.target.files[0];

        if (file) {
            var reader = new FileReader();
            reader.onload = (onLoadE) => {
                console.log(onLoadE);
                if (onLoadE.total / 1000000 <= MAX_FILE_UPLOAD_SIZE) {
                    setFormData({ ...formData, photoURL: reader.result, photo: file });
                } else {
                    let notify = new Notification();
                    notify.setNotification(translate.t("MaxFileSizeExceeded", { maxFileSize: MAX_FILE_UPLOAD_SIZE }), "danger");
                    setFileInputKey(fileInputKey + 1);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{formData.athleteID && formData.athleteID != 0 ? translate.t("EditAthlete") : translate.t("AddAthlete")}</h4>
                    {allLanguageMutations && allLanguageMutations.length > 1 && (
                        <div className="ml-auto">
                            <select className="form-control" name="selectedLang" onChange={(e) => athleteLib.formHandle(e)}>
                                {allLanguageMutations &&
                                    allLanguageMutations.map((item, index) => (
                                        <option key={index} value={item.suffix}>
                                            {" "}
                                            {item.suffix}{" "}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    )}
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ? (
                        <Error text={error} />
                    ) : (
                        <div>
                            {!getLoading && !addLoading ? (
                                <Fragment>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="input-label">*{translate.t("Name")}</label>
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control"
                                                                athlete="text"
                                                                name="name"
                                                                value={formData.name}
                                                                onChange={(e) => athleteLib.formHandle(e, formData, setFormData)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="input-label">*{translate.t("Surname")}</label>
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control"
                                                                athlete="text"
                                                                name="surname"
                                                                value={formData.surname}
                                                                onChange={(e) => athleteLib.formHandle(e, formData, setFormData)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="input-label">*{translate.t("Email")}</label>
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control"
                                                                athlete="text"
                                                                name="email"
                                                                value={formData.email}
                                                                onChange={(e) => athleteLib.formHandle(e, formData, setFormData)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="input-label">*{translate.t("Sex")}</label>
                                                        <div className="form-group">
                                                            <select
                                                                value={formData.sexID}
                                                                className="form-control"
                                                                name="sexID"
                                                                onChange={(e) => athleteLib.formHandle(e, formData, setFormData)}
                                                            >
                                                                <option value={0}>-- {translate.t("ChooseSex")} --</option>
                                                                {athleteData?.sexList?.map((item, index) => (
                                                                    <option key={index} value={item.sexID}>
                                                                        {translate.t(item.name)}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="input-label">{translate.t("Phone")}</label>
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control"
                                                                athlete="text"
                                                                name="phone"
                                                                value={formData.phone}
                                                                onChange={(e) => athleteLib.formHandle(e, formData, setFormData)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="input-label">*{translate.t("BirthDate")}</label>
                                                        <div className="form-group date-w-100">
                                                            <DatePicker
                                                                className="form-control w-100"
                                                                dateFormat="yyyy-MM-dd"
                                                                locale={translate.locale}
                                                                selected={formData.birthDate}
                                                                onChange={(date) => setFormData({ ...formData, birthDate: date })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-4">
                                            <div className="form-group input-group">
                                                <div className="form-group">
                                                    <label className="input-label">{translate.t("AthletePhoto")}</label>
                                                    {!formData.photoURL && !formData.photo ? (
                                                        <div className="custom-file">
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="customPhoto"
                                                                name="photo"
                                                                accept="image/*"
                                                                onChange={(e) => openImage(e)}
                                                                key={fileInputKey}
                                                            />
                                                            <label className="custom-file-label" htmlFor="customPhoto">
                                                                {translate.t("ChoosePhoto")}
                                                            </label>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <div className="d-flex flex-column align-items-start">
                                                                <img className="maxwidth-100 athlete-img" src={formData.photoURL} />
                                                                <img
                                                                    onClick={() => setFormData({ ...formData, photo: "", photoURL: "" })}
                                                                    className="remove-icon mt-2"
                                                                    src={remove}
                                                                    title={translate.t("Remove")}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="input-label">*{translate.t("Country")}</label>
                                                        <div className="form-group">
                                                            <select
                                                                value={formData.countryID}
                                                                className="form-control"
                                                                name="countryID"
                                                                onChange={(e) => setFormData({ ...formData, countryID: e.target.value, clubID: 0 })}
                                                            >
                                                                <option value={0}>-- {translate.t("ChooseCountry")} --</option>
                                                                {athleteData?.allCountries?.map((item, index) => (
                                                                    <option key={index} value={item.countryID}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="input-label">{translate.t("Club")}</label>
                                                        <div className="form-group">
                                                            <select
                                                                value={formData.clubID}
                                                                className="form-control"
                                                                name="clubID"
                                                                onChange={(e) => athleteLib.formHandle(e, formData, setFormData)}
                                                            >
                                                                <option value={0}>-- {translate.t("ChooseClub")} --</option>
                                                                {athleteData?.clubs
                                                                    ?.filter((item) => item.countryID == formData.countryID)
                                                                    .map((item, index) => (
                                                                        <option key={index} value={item.clubID}>
                                                                            {item.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="input-label">*{translate.t("InternationalLicence")}</label>
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control"
                                                                athlete="text"
                                                                name="internationalLicence"
                                                                value={formData.internationalLicence}
                                                                onChange={(e) => athleteLib.formHandle(e, formData, setFormData)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="input-label">{translate.t("NationalLicence")}</label>
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control"
                                                                athlete="text"
                                                                name="licenceNumber"
                                                                value={formData.licenceNumber}
                                                                onChange={(e) => athleteLib.formHandle(e, formData, setFormData)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="input-label">{translate.t("Biography")}</label>
                                                        <div className="form-group">
                                                            <textarea
                                                                className="form-control"
                                                                name="bibliography"
                                                                value={formData.bibliography}
                                                                onChange={(e) => athleteLib.formHandle(e, formData, setFormData)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                {formData.athleteID && formData.athleteID == 0 ? (
                                                    <label className="input-label">*{translate.t("Password")}</label>
                                                ) : (
                                                    <label className="input-label">{translate.t("NewPassword")}</label>
                                                )}
                                                <div className="input-group mb-2">
                                                    <input
                                                        className="form-control"
                                                        athlete="text"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={(e) => athleteLib.formHandle(e, formData, setFormData)}
                                                    />
                                                    <div className="input-group-append">
                                                        <div className="input-group-text h-100">
                                                            <img className="hp-30 cursor-pointer" onClick={() => generateNewPwd()} src={refreshIcon} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                </div>
                <div className="modal-footer">
                    <button athlete="button" className="btn btn-primary flex-fill" onClick={() => addAthleteData()}>
                        {formData.athleteID && formData.athleteID != 0 ? translate.t("Edit") : translate.t("Add")}
                    </button>
                    <button athlete="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                        {translate.t("Close")}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AddEditAthlete;
