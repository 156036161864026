/*
    Přidání štítku
*/
import React, { Fragment, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import { ADD_EDIT_HEAT, GET_HEAT } from "../Queries/heat";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import HeatTeamList from "./HeatTeamList";

const AddEditHeat = (props) => {
    const [selectedTab, setTab] = useState(1);

    const [formData, setFormData] = useState({
        heatID: props.selectedHeatID,
        disciplineID: props.disciplineID,
        heatOrder: "",
    });

    const {
        loading: getLoading,
        error: getError,
        data: heatData,
    } = useQuery(GET_HEAT, {
        variables: { heatID: formData.heatID },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            if (formData.heatID != "") {
                setFormData({
                    ...formData,
                    heatOrder: data.heat.heatOrder,
                });
            }
        },
    });

    const [addHeat, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_HEAT, {
        onCompleted(data) {
            setFormData({ ...formData, heatID: data.addEditHeat.heatID });
            //props.openCloseModal(false);
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));

            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const addHeatData = () => {
        if (!!formData.heatOrder) {
            let dataToSend = {
                ...formData,
                heatOrder: parseInt(formData.heatOrder),
            };
            addHeat({
                variables: {
                    addEditData: dataToSend,
                },
            });
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("NotFilledRequiredFields"), "danger");
        }
    };

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg heat-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{formData.heatID && formData.heatID != "" ? translate.t("EditHeat") : translate.t("AddHeat")}</h4>

                    <button type="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ? (
                        <Error text={error} />
                    ) : (
                        <div>
                            {!getLoading && !addLoading ? (
                                <Fragment>
                                    <ul className={`nav nav-tabs`} role="tablist">
                                        <li className={`nav-item mr-2`}>
                                            <a className={`nav-link ${selectedTab == 1 ? `active` : ""}`} onClick={(e) => setTab(1)}>
                                                {translate.t("MainData")}
                                            </a>
                                        </li>
                                        <li className={`nav-item mr-2`}>
                                            <a
                                                className={`nav-link ${selectedTab == 2 ? `active` : ""}  ${!formData.heatID ? "disabled" : ""}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (formData.heatID) setTab(2);
                                                }}
                                            >
                                                {translate.t("AthletesSlashTeams")}
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        {selectedTab == 1 && (
                                            <>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="input-label">*{translate.t("HeatNumber")}</label>
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    type="number"
                                                                    inputmode="numeric"
                                                                    step={1}
                                                                    value={formData.heatOrder}
                                                                    onChange={(e) => setFormData({ ...formData, heatOrder: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-end mt-4">
                                                    <button type="button" className="btn btn-primary mr-2" onClick={() => addHeatData()}>
                                                        {formData.heatID && formData.heatID != "" ? translate.t("Edit") : translate.t("Add")}
                                                    </button>
                                                    <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                                                        {translate.t("Close")}
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                        {selectedTab == 2 && <HeatTeamList heatID={formData.heatID} disciplineID={props.disciplineID} />}
                                    </div>
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default AddEditHeat;
