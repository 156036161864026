import React from "react";
import ReactDOM from "react-dom/client";
import "react-tooltip/dist/react-tooltip.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./Public/Css/main.css";
import "./Public/Css/xlg.css";
import "./Public/Css/lg.css";
import "./Public/Css/md.css";
import "./Public/Css/sm.css";
import "./Public/Css/xs.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <IntlProvider locale="cs" defaultLocale="en">
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </IntlProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
