import React, { Component } from "react";
import TinyMCEFileManager from "./TinyMCEFileManager";
import { Editor } from "@tinymce/tinymce-react";

const INITIAL_STATE = {
    showManager: false,
    tinyMCECallBack: "",
    tinyMCEFileType: "",
};

class TinyMCEEditor extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.hideManager = this.hideManager.bind(this);
    }

    hideManager() {
        this.setState({
            showManager: false,
        });
    }

    render() {
        const { showManager, tinyMCECallBack, tinyMCEFileType } = this.state;

        return (
            <div>
                <Editor
                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                    name="description"
                    initialValue={this.props.initialValue}
                    init={{
                        promotion: false,
                        plugins: "link image code lists",
                        toolbar: "undo redo bold italic alignleft aligncenter alignright numlist bullist checklist link image code",
                        image_advtab: true,
                        file_picker_types: "file image",
                        file_picker_callback: (callback, value, meta) => {
                            if (meta.filetype == "image" || meta.filetype == "file") {
                                this.setState({ showManager: true, tinyMCECallBack: callback, tinyMCEFileType: meta.filetype });
                            }
                        },
                    }}
                    onChange={this.props.onChange}
                />
                {showManager && <TinyMCEFileManager callback={this.hideManager} tinyMCECallBack={tinyMCECallBack} tinyMCEFileType={tinyMCEFileType} />}
            </div>
        );
    }
}

export default TinyMCEEditor;
