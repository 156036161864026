/*
    Přidání štítku
*/
import React, { Fragment, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import { ADD_EDIT_CLUB, GET_CLUB } from "../Queries/club";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import EditClubLogo from "./EditClubLogo";

const AddEditClub = (props) => {
    const [selectedTab, setTab] = useState(1);

    const [formData, setFormData] = useState({
        clubID: props.selectedClubID,
        name: "",
        email: "",
        web: "",
        countryID: "0",
        sportIDs: [],
        federationID: "0",
    });

    const {
        loading: getLoading,
        error: getError,
        data: clubData,
    } = useQuery(GET_CLUB, {
        variables: { clubID: formData.clubID, lang: translate.locale },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            if (formData.clubID != "") {
                setFormData({
                    ...formData,
                    name: data.club.name,
                    web: data.club.web,
                    email: data.club.email,
                    countryID: data.club.countryID,
                    federationID: data.club.federationID,
                    sportIDs: data.club.sports.map((item) => item.sportID),
                });
            }
        },
    });

    const [addClub, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_CLUB, {
        onCompleted(data) {
            setFormData({ ...formData, clubID: data.addEditClub.clubID });
            props.openCloseModal(false);
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));

            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const addClubData = () => {
        if (!!formData.name && formData.countryID != "0" && formData.sportIDs.length > 0) {
            let dataToSend = {
                ...formData,
            };
            addClub({
                variables: {
                    addEditData: dataToSend,
                },
            });
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("NotFilledRequiredFields"), "danger");
        }
    };

    const changeSelectedSports = (item) => {
        if (formData.sportIDs.includes(item.sportID)) {
            setFormData({ ...formData, sportIDs: [...formData.sportIDs.filter((filterItem) => filterItem != item.sportID)] });
        } else {
            formData.sportIDs.push(item.sportID);
            setFormData({ ...formData, sportIDs: [...formData.sportIDs] });
        }
    };

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg club-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{formData.clubID && formData.clubID != "" ? translate.t("EditClub") : translate.t("AddClub")}</h4>

                    <button type="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ? (
                        <Error text={error} />
                    ) : (
                        <div>
                            {!getLoading && !addLoading ? (
                                <Fragment>
                                    <ul className={`nav nav-tabs`} role="tablist">
                                        <li className={`nav-item mr-2`}>
                                            <a className={`nav-link ${selectedTab == 1 ? `active` : ""}`} onClick={(e) => setTab(1)}>
                                                {translate.t("MainData")}
                                            </a>
                                        </li>
                                        <li className={`nav-item mr-2`}>
                                            <a
                                                className={`nav-link ${selectedTab == 2 ? `active` : ""}  ${!formData.clubID ? "disabled" : ""}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (formData.clubID) setTab(2);
                                                }}
                                            >
                                                {translate.t("Logo")}
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        {selectedTab == 1 && (
                                            <>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="input-label">*{translate.t("ClubTitle")}</label>
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={formData.name}
                                                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="input-label">*{translate.t("Email")}</label>
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={formData.email}
                                                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="input-label">*{translate.t("Web")}</label>
                                                            <div className="form-group">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={formData.web}
                                                                    onChange={(e) => setFormData({ ...formData, web: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="input-label">*{translate.t("Country")}</label>
                                                            <div className="form-group">
                                                                <select
                                                                    value={formData.countryID}
                                                                    className="form-control"
                                                                    name="roleID"
                                                                    onChange={(e) => setFormData({ ...formData, countryID: e.target.value })}
                                                                >
                                                                    <option value={0}>-- {translate.t("ChooseCountry")} --</option>
                                                                    {clubData &&
                                                                        clubData.allCountries.map((item, index) => (
                                                                            <option key={index} value={item.countryID}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="input-label">{translate.t("Federation")}</label>
                                                            <div className="form-group">
                                                                <select
                                                                    value={formData.federationID}
                                                                    className="form-control"
                                                                    name="roleID"
                                                                    onChange={(e) => setFormData({ ...formData, federationID: e.target.value })}
                                                                >
                                                                    <option value={0}>-- {translate.t("ChooseFederation")} --</option>
                                                                    {clubData &&
                                                                        clubData.federations.map((item, index) => (
                                                                            <option key={index} value={item.federationID}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card main">
                                                    <div className="card-header d-flex align-items-center">{translate.t("Sports")}</div>
                                                    <div className="card-body">
                                                        <div className="d-flex">
                                                            {clubData.sports.map((item, index) => (
                                                                <div className="custom-control custom-checkbox custom-control-inline" key={index}>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`sportCheckbox-${item.sportID}`}
                                                                        name="customRadioInline"
                                                                        className="custom-control-input"
                                                                        value={item.sportID}
                                                                        checked={formData.sportIDs.includes(item.sportID)}
                                                                        onChange={() => changeSelectedSports(item)}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor={`sportCheckbox-${item.sportID}`}>
                                                                        {item.title}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end mt-4">
                                                    <button type="button" className="btn btn-primary mr-2" onClick={() => addClubData()}>
                                                        {formData.clubID && formData.clubID != "" ? translate.t("Edit") : translate.t("Add")}
                                                    </button>
                                                    <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                                                        {translate.t("Close")}
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                        {selectedTab == 2 && (
                                            <EditClubLogo clubID={formData.clubID} openCloseModal={(status) => props.openCloseModal(status)} />
                                        )}
                                    </div>
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default AddEditClub;
