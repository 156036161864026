/**
 *  komponenta pro štítky pro produkty
 */
import React, { Fragment, useState } from "react";
import AddEditLadder from "./AddEditLadder";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_LADDERS, DELETE_LADDERS } from "../Queries/ladder";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import LadderLib from "../Library/ladder";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";

const Ladders = (props) => {
    const client = useApolloClient();
    //zjistime jestli ma prihlaseny administrator spravna prava
    const helper = new Helper();
    const adminUserData = helper.checkAdminRights({ ...props, client }, ["1", "2", "3"]);

    const listVariables = {
        filters: {
            offset: 0,
            limit: 50,
            lang: translate.locale,
        },
    };

    const [selectedLadders, setSelectedLadders] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedLadderHashID, setSelectedLadderHashID] = useState("");
    const {
        loading,
        error: getError,
        data: ladderListData,
        fetchMore,
        refetch,
    } = useQuery(GET_LADDERS, {
        variables: listVariables,
        fetchPolicy: "cache-and-network",
    });

    const [deleteLadder, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_LADDERS, {
        update(cache, response) {
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
            refetch({ filters: { ...listVariables.filters, limit: ladderListData.laddersByRole.length } });
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toString(), "danger");
        },
    });

    const openCloseModal = (open, hashID) => {
        setSelectedLadderHashID(hashID);
        setShowAdd(open);
    };

    /**
     * smazání štítků
     * @param {*} deleteLadders funkce, která posílá data na server
     * @param {*} hashID ID ladderu
     */

    const initiateDeleteLadder = (e, hashID) => {
        var checked = e.target.checked;
        var arr = [...selectedLadders];

        if (!checked) {
            for (let i in selectedLadders) {
                if (selectedLadders[i] == hashID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [hashID, ...arr];
        }

        setSelectedLadders(arr);
    };

    const delLadder = (action) => {
        if (action) {
            let hashIDs = selectedLadders.join(",");

            deleteLadder({
                variables: {
                    hashIDs: hashIDs,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedLadders.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <div id="settings" className="whole-container">
            <div className="row">
                <div className="col-sm one">
                    <div className="card main">
                        <div className="card-header d-flex align-items-center">
                            {translate.t("LadderList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, "")}>
                                {translate.t("Add")}
                            </button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                                {translate.t("DeleteSelected")}
                            </button>
                        </div>
                        <div className="card-body">
                            {!error ? (
                                <div>
                                    <div className="data-list">
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("LadderTitle")}</div>
                                                {["1", "2"].includes(adminUserData.adminUser.roleID) && <div>{translate.t("AddedByOrganiser")}</div>}
                                                {["1", "2"].includes(adminUserData.adminUser.roleID) && <div>{translate.t("AssignedToOrganisers")}</div>}
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {ladderListData?.laddersByRole?.map((item, index) => {
                                                var checked = false;
                                                for (let i in selectedLadders) {
                                                    if (selectedLadders[i] == item.hashID) checked = true;
                                                }

                                                return (
                                                    <div key={index} className="data-list-item-content">
                                                        <div>{item.title}</div>
                                                        {["1", "2"].includes(adminUserData.adminUser.roleID) && <div>{item?.ownerOrganiser?.name}</div>}
                                                        {["1", "2"].includes(adminUserData.adminUser.roleID) && (
                                                            <div>
                                                                {item?.organisers?.map((item, index) => (
                                                                    <div key={index}>
                                                                        {item.name} {item.surname}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        <div className="text-right">
                                                            {["1", "2"].includes(adminUserData.adminUser.roleID) || item.canEditLadder ? (
                                                                <img onClick={() => openCloseModal(true, item.hashID)} className="edit-icon" src={edit} />
                                                            ) : null}
                                                            {["1", "2"].includes(adminUserData.adminUser.roleID) || item.canDeleteLadder ? (
                                                                <input
                                                                    className="delete-checked"
                                                                    type="checkbox"
                                                                    name="deleteLadder[]"
                                                                    checked={checked}
                                                                    onChange={(e) => initiateDeleteLadder(e, item.hashID)}
                                                                />
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Fragment>
                                    </div>
                                    {!loading ? (
                                        <Fragment>
                                            {!loading && (
                                                <>
                                                    {!!ladderListData?.laddersByRole?.length ? (
                                                        <>
                                                            {ladderListData.laddersByRole.length < ladderListData.laddersByRoleCount && (
                                                                <div className="text-center p-5">
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={() =>
                                                                            fetchMore({
                                                                                variables: {
                                                                                    ...listVariables,
                                                                                    filters: {
                                                                                        ...listVariables.filters,
                                                                                        offset: ladderListData.laddersByRole.length,
                                                                                    },
                                                                                },
                                                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                                                    if (!fetchMoreResult) return prev;
                                                                                    return Object.assign({}, prev, {
                                                                                        laddersByRole: [
                                                                                            ...prev.laddersByRole,
                                                                                            ...fetchMoreResult.laddersByRole,
                                                                                        ],
                                                                                    });
                                                                                },
                                                                            })
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        {translate.t("LoadMore")}{" "}
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                                    )}
                                                </>
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Loading />
                                    )}
                                </div>
                            ) : (
                                <Fragment>{error && <Error text={error} />}</Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAdd && (
                <AddEditLadder
                    openCloseModal={openCloseModal}
                    selectedLadderHashID={selectedLadderHashID}
                    updated={() =>
                        refetch({
                            filters: {
                                ...listVariables.filters,
                                limit:
                                    listVariables.filters.limit > ladderListData.laddersByRole.length
                                        ? listVariables.filters.limit
                                        : ladderListData.laddersByRole.length,
                            },
                        })
                    }
                />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delLadder} />}
        </div>
    );
};

export default Ladders;
