import Notification from "../../../Library/notification";

class Banner {
    constructor(props) {
        this.props = props;
    }

    /**
     * Přidání jídla
     * @param {*} addBanner funkce pro přidání
     * @param {*} formData aktuální data jídla
     */

    addBanner(addBanner, formData) {
        if (formData.photo != "" || formData.bannerID != 0) {
            formData.active = parseInt(formData.active);

            addBanner({
                variables: formData,
            });
        } else {
            let notify = new Notification();
            notify.setNotification("Nevybrali jste fotku", "danger");
        }
    }

    /**
     * Vytáhne data pro dané jídlo
     * @param {*} data data z databáze
     * @param {*} bannerID ID jídla
     * @param {*} formData aktuální data pro jídlo
     * @param {*} setFormData funkce která data uloží do aktuálních dat
     */

    getData(data, bannerID, formData, setFormData) {
        if (bannerID == 0) {
            let langs = this.completeLangsArray([], data.allLanguageMutations);
            setFormData({ ...formData, langs: langs });
        } else {
            setFormData({
                ...formData,
                active: data.bannerWithLangs.active,
                bannerID: data.bannerWithLangs.bannerID,
                langs: this.completeLangsArray(data.bannerWithLangs.langs, data.allLanguageMutations),
            });
        }
    }

    /**
     *  Pro vyplnění jazykových mutací
     */

    completeLangsArray = (langsFromDb, allActiveLanguages) => {
        let langs = langsFromDb;
        for (const l of allActiveLanguages) {
            let langSet = false;
            for (const lang of langs) {
                if (lang.__typename) delete lang["__typename"];
                if (l.suffix == lang.lang) {
                    langSet = true;
                    break;
                }
            }

            if (!langSet) {
                langs.push({
                    title: "",
                    text: "",
                    url: "",
                    lang: l.suffix,
                });
            }
        }
        return langs;
    };

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */

    formHandle(e, formData, setFormData) {
        const t = e.target;
        const v = t.type === "checkbox" ? t.checked : t.value;
        const n = t.name;

        setFormData({ ...formData, [n]: v });
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */

    formLangHandle(e, formData, setFormData) {
        const t = e.target;
        const v = t.type === "checkbox" ? t.checked : t.value;
        const n = t.name;

        var langs = formData.langs;

        for (const lang of langs) {
            if (lang.lang == formData.selectedLang) {
                lang[n] = v;
            }
        }

        setFormData({ ...formData, langs: langs });
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} propertyName - název proměnné v objektu lang
     *
     * @returns property value
     */
    getLangValue = (formData, propertyName) => {
        for (const lang of formData.langs) {
            if (lang.lang == formData.selectedLang) {
                return lang[propertyName];
            }
        }
        return "";
    };

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
     * @param {String} data data tinyMCE editoru
     * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
     */

    formLangHandleEditor(content, paramName, formData, setFormData) {
        let langs = formData.langs;
        for (const lang of langs) {
            if (lang.lang == formData.selectedLang) {
                lang[paramName] = content;
            }
        }

        setFormData({ ...formData, langs: langs });
    }
}

export default Banner;
