import { ADMIN_USER } from "../GlobalQueries/globalQueries";

/**
 *  Pomocná třída s obecnými funkcemi
 */
class Helper {
    getApolloErrorText(error) {
        error = JSON.stringify(error);
        error = JSON.parse(error);

        if (error?.graphQLErrors[0]) return error.graphQLErrors[0].message;
        if (error?.networkError?.result?.errors) {
            return error.networkError.result.errors[0].message;
        }
    }

    validateEmail(email) {
        var re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    goToInitialPage(history) {
        history.push("/competitions");
    }

    checkAdminRights(props, roleIDRestrictionArray) {
        const adminUserData = props.client.readQuery({ query: ADMIN_USER });
        if (!(!!adminUserData && roleIDRestrictionArray.includes(adminUserData?.adminUser?.roleID))) {
            this.goToInitialPage(props.history);
        }
        return adminUserData;
    }

    makeid(length) {
        var result = "";
        var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} paramName - název proměnné v objektu lang
     *
     * @returns property value
     */
    getLangValue(formData, paramName, selectedLang) {
        for (const lang of formData.langs) {
            if (lang.lang == selectedLang) {
                if (lang[paramName] === null) {
                    return "";
                } else {
                    return lang[paramName];
                }
            }
        }
        return "";
    }
    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Object} formData object obsahujici data formulare
     * @param {Function} setFormData funkce nastavující data formulare
     */
    formLangHandle(e, formData, setFormData, selectedLang) {
        const t = e.target;
        const v = t.type === "checkbox" ? t.checked : t.value;
        const n = t.name;

        let langs = formData.langs;
        for (const lang of langs) {
            if (lang.lang == selectedLang) {
                lang[n] = v;
            }
        }

        setFormData({ ...formData, langs: langs });
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
     * @param {String} data data tinyMCE editoru
     * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
     * @param {Object} formData object obsahujici data formulare
     * @param {Function} setFormData funkce nastavující data formulare
     */
    formLangHandleEditor(content, paramName, formData, setFormData, selectedLang) {
        let langs = formData.langs;
        for (const lang of langs) {
            if (lang.lang == selectedLang) {
                lang[paramName] = content;
            }
        }

        setFormData({ ...formData, langs: langs });
    }

    copyObjectsWithoutParam(objects, paramToOmit) {
        return JSON.parse(
            JSON.stringify(
                objects.map((obj) => {
                    const newObj = { ...obj };
                    delete newObj[paramToOmit];
                    return newObj;
                })
            )
        );
    }
}

export default Helper;
