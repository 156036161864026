/**
 *  komponenta pro štítky pro produkty
 */
import React, { Fragment, useState } from "react";
import AddEditRankingPoint from "./AddEditRankingPoint";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_GLOBAL_DISCIPLINES, DELETE_GLOBAL_DISCIPLINES } from "../Queries/rankingPoint";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import SearchInput from "../../General/Components/SearchInput";

const RankingPoints = (props) => {
    const client = useApolloClient();
    //zjistime jestli ma prihlaseny administrator spravna prava
    const helper = new Helper();
    helper.checkAdminRights({ ...props, client }, ["1", "2"]);

    const [searchQuery, setSearchQuery] = useState("");
    const [listVariables, setListVariables] = useState({
        filters: {
            offset: 0,
            limit: 50,
            searchQuery: "",
            sportID: 0,
        },
        lang: translate.locale,
    });

    const [selectedRankingPoints, setSelectedRankingPoints] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedRankingPointID, setSelectedRankingPointID] = useState("");
    const {
        loading,
        error: getError,
        data: rankingPointListData,
        fetchMore,
        refetch,
    } = useQuery(GET_GLOBAL_DISCIPLINES, {
        variables: listVariables,
        fetchPolicy: "cache-and-network",
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toString(), "danger");
        },
    });

    const [deleteRankingPoint, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_GLOBAL_DISCIPLINES, {
        update(cache, response) {
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
            refetch({ filters: { ...listVariables.filters, limit: rankingPointListData.allRankingPoints.length } });
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toString(), "danger");
        },
    });

    const openCloseModal = (open, rankingPointID) => {
        setSelectedRankingPointID(rankingPointID);
        setShowAdd(open);
    };

    /**
     * smazání štítků
     * @param {*} deleteRankingPoints funkce, která posílá data na server
     * @param {*} rankingPointID ID rankingPointu
     */

    const initiateDeleteRankingPoint = (e, rankingPointID) => {
        var checked = e.target.checked;
        var arr = [...selectedRankingPoints];

        if (!checked) {
            for (let i in selectedRankingPoints) {
                if (selectedRankingPoints[i] == rankingPointID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [rankingPointID, ...arr];
        }

        setSelectedRankingPoints(arr);
    };

    const delRankingPoint = (action) => {
        if (action) {
            let rankingPointIDs = selectedRankingPoints.join(",");

            deleteRankingPoint({
                variables: {
                    rankingPointIDs: rankingPointIDs,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedRankingPoints.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <div id="settings" className="whole-container">
            <div className="row">
                <div className="col-sm one">
                    <div className="card main">
                        <div className="card-header d-flex align-items-center">
                            {translate.t("RankingPointList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, "")}>
                                {translate.t("Add")}
                            </button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                                {translate.t("DeleteSelected")}
                            </button>
                        </div>
                        <div className={`d-flex px-3 py-2 bg-light border-bottom align-items-center`}>
                            <div className="form-group">
                                <div className="text-nowrap fs12">{translate.t("Sport")}</div>
                                <select
                                    className="form-control form-control-sm"
                                    value={listVariables.filters.sportID}
                                    onChange={(e) =>
                                        setListVariables({ ...listVariables, filters: { ...listVariables.filters, sportID: parseInt(e.target.value) } })
                                    }
                                >
                                    <option value={0}>-- {translate.t("ChooseSport")} --</option>
                                    {rankingPointListData?.sports.map((item, index) => (
                                        <option key={index} value={item.sportID}>
                                            {item.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group ml-auto">
                                <div className="text-nowrap fs12">{translate.t("Search")}</div>
                                <SearchInput
                                    className={"form-control form-control-sm "}
                                    value={searchQuery}
                                    onChange={(text) => {
                                        setListVariables({ ...listVariables, filters: { ...listVariables.filters, searchQuery: text, offset: 0 } });
                                    }}
                                    textChanged={(text) => {
                                        setSearchQuery(text);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="card-body">
                            {!error ? (
                                <div>
                                    <div className="data-list">
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("Rank")}</div>
                                                <div className="text-center">{translate.t("PointsCountToAdd")}</div>
                                                <div>{translate.t("Sport")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {rankingPointListData?.allRankingPoints?.map((item, index) => {
                                                var checked = false;
                                                for (let i in selectedRankingPoints) {
                                                    if (selectedRankingPoints[i] == item.rankingPointID) checked = true;
                                                }

                                                return (
                                                    <div key={index} className="data-list-item-content">
                                                        <div>{item.rank}</div>
                                                        <div className="text-center">{item.points}</div>
                                                        <div>{item.sport?.title}</div>

                                                        <div className="text-right">
                                                            <img onClick={() => openCloseModal(true, item.rankingPointID)} className="edit-icon" src={edit} />
                                                            <input
                                                                className="delete-checked"
                                                                type="checkbox"
                                                                name="deleteRankingPoint[]"
                                                                checked={checked}
                                                                onChange={(e) => initiateDeleteRankingPoint(e, item.rankingPointID)}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Fragment>
                                    </div>
                                    {!loading ? (
                                        <Fragment>
                                            {!loading && (
                                                <>
                                                    {!!rankingPointListData?.allRankingPoints?.length ? (
                                                        <>
                                                            {rankingPointListData.allRankingPoints.length < rankingPointListData.rankingPointsCount && (
                                                                <div className="text-center p-5">
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={() =>
                                                                            fetchMore({
                                                                                variables: {
                                                                                    ...listVariables,
                                                                                    filters: {
                                                                                        ...listVariables.filters,
                                                                                        offset: rankingPointListData.allRankingPoints.length,
                                                                                    },
                                                                                },
                                                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                                                    if (!fetchMoreResult) return prev;
                                                                                    return Object.assign({}, prev, {
                                                                                        allRankingPoints: [
                                                                                            ...prev.allRankingPoints,
                                                                                            ...fetchMoreResult.allRankingPoints,
                                                                                        ],
                                                                                    });
                                                                                },
                                                                            })
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        {translate.t("LoadMore")}{" "}
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                                    )}
                                                </>
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Loading />
                                    )}
                                </div>
                            ) : (
                                <Fragment>{error && <Error text={error} />}</Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAdd && (
                <AddEditRankingPoint
                    openCloseModal={openCloseModal}
                    selectedRankingPointID={selectedRankingPointID}
                    listVariables={listVariables}
                    updated={() =>
                        refetch({
                            filters: {
                                ...listVariables.filters,
                                limit:
                                    listVariables.filters.limit > rankingPointListData.allRankingPoints.length
                                        ? listVariables.filters.limit
                                        : rankingPointListData.allRankingPoints.length,
                            },
                        })
                    }
                />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delRankingPoint} />}
        </div>
    );
};

export default RankingPoints;
