/**
 *  komponenta pro štítky pro produkty
 */
import React, { Component, Fragment, useState } from "react";
import AddEditAgeCategory from "./AddEditAgeCategory";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_AGECATEGORYS, DELETE_AGECATEGORYS } from "../Queries/ageCategory";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import AgeCategoryLib from "../Library/ageCategory";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import SearchInput from "../../General/Components/SearchInput";

const AgeCategorys = (props) => {
    const client = useApolloClient();
    //zjistime jestli ma prihlaseny administrator spravna prava
    const helper = new Helper();
    helper.checkAdminRights({ ...props, client }, ["1", "2"]);

    const [searchQuery, setSearchQuery] = useState("");
    const [listVariables, setListVariables] = useState({
        filters: {
            offset: 0,
            limit: 50,
            searchQuery: "",
            sportID: 0,
            lang: translate.locale,
        },
    });

    const [selectedAgeCategorys, setSelectedAgeCategorys] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedAgeCategoryID, setSelectedAgeCategoryID] = useState(0);
    const {
        loading,
        error: getError,
        data: ageCategoryListData,
        fetchMore,
        refetch,
    } = useQuery(GET_AGECATEGORYS, {
        variables: listVariables,
        fetchPolicy: "cache-and-network",
    });

    const [deleteAgeCategory, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_AGECATEGORYS, {
        update(cache, response) {
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
            console.log(ageCategoryListData);
            refetch({ filters: { ...listVariables.filters, limit: ageCategoryListData.allAgeCategorys.length } });
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toString(), "danger");
        },
    });

    const openCloseModal = (open, ageCategoryID) => {
        setSelectedAgeCategoryID(ageCategoryID);
        setShowAdd(open);
    };

    /**
     * smazání štítků
     * @param {*} deleteAgeCategorys funkce, která posílá data na server
     * @param {*} ageCategoryID ID ageCategoryu
     */

    const initiateDeleteAgeCategory = (e, ageCategoryID) => {
        var checked = e.target.checked;
        var arr = [...selectedAgeCategorys];

        if (!checked) {
            for (let i in selectedAgeCategorys) {
                if (selectedAgeCategorys[i] == ageCategoryID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [ageCategoryID, ...arr];
        }

        setSelectedAgeCategorys(arr);
    };

    const delAgeCategory = (action) => {
        if (action) {
            let ageCategoryIDs = selectedAgeCategorys.join(",");

            deleteAgeCategory({
                variables: {
                    ageCategoryIDs: ageCategoryIDs,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedAgeCategorys.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <div id="settings" className="whole-container">
            <div className="row">
                <div className="col-sm one">
                    <div className="card main">
                        <div className="card-header d-flex align-items-center">
                            {translate.t("AgeCategoryList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, 0)}>
                                {translate.t("Add")}
                            </button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                                {translate.t("DeleteSelected")}
                            </button>
                        </div>
                        <div className={`d-flex px-3 py-2 bg-light border-bottom align-items-center`}>
                            <div className="form-group mr-3">
                                <div className="text-nowrap fs12">{translate.t("Sport")}</div>
                                <select
                                    className="form-control form-control-sm"
                                    value={listVariables.filters.sportID}
                                    onChange={(e) => setListVariables({ ...listVariables, filters: { ...listVariables.filters, sportID: e.target.value } })}
                                >
                                    <option value={0}>-- {translate.t("Choose")} --</option>
                                    {ageCategoryListData?.sports.map((item, index) => (
                                        <option key={index} value={item.sportID}>
                                            {item.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group ml-auto">
                                <div className="text-nowrap fs12">{translate.t("Search")}</div>
                                <SearchInput
                                    className={"form-control form-control-sm "}
                                    value={searchQuery}
                                    onChange={(text) => {
                                        setListVariables({ ...listVariables, filters: { ...listVariables.filters, searchQuery: text, offset: 0 } });
                                    }}
                                    textChanged={(text) => {
                                        setSearchQuery(text);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="card-body">
                            {!error ? (
                                <div>
                                    <div className="data-list">
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("AgeCategoryTitle")}</div>
                                                <div className="text-center">
                                                    {translate.t("AgeLimit")} ({translate.t("Years")})
                                                </div>
                                                <div className="text-center">{translate.t("Sport")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {ageCategoryListData &&
                                                ageCategoryListData.allAgeCategorys &&
                                                ageCategoryListData.allAgeCategorys.map((item, index) => {
                                                    var checked = false;
                                                    for (let i in selectedAgeCategorys) {
                                                        if (selectedAgeCategorys[i] == item.ageCategoryID) checked = true;
                                                    }

                                                    return (
                                                        <div key={index} className="data-list-item-content">
                                                            <div>{item.title}</div>
                                                            <div className="text-center">
                                                                {item.ageLimitFrom ? item.ageLimitFrom : translate.t("Unlimited")} -{" "}
                                                                {item.ageLimitTo ? item.ageLimitTo : translate.t("Unlimited")}
                                                            </div>
                                                            <div className="text-center">{item?.sport?.title}</div>
                                                            <div className="text-right">
                                                                <img
                                                                    onClick={() => openCloseModal(true, item.ageCategoryID)}
                                                                    className="edit-icon"
                                                                    src={edit}
                                                                />
                                                                <input
                                                                    className="delete-checked"
                                                                    type="checkbox"
                                                                    name="deleteAgeCategory[]"
                                                                    checked={checked}
                                                                    onChange={(e) => initiateDeleteAgeCategory(e, item.ageCategoryID)}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </Fragment>
                                    </div>
                                    {!loading ? (
                                        <Fragment>
                                            {!loading && (
                                                <>
                                                    {!!ageCategoryListData?.allAgeCategorys?.length ? (
                                                        <>
                                                            {ageCategoryListData.allAgeCategorys.length < ageCategoryListData.ageCategoryListCount && (
                                                                <div className="text-center p-5">
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={() =>
                                                                            fetchMore({
                                                                                variables: {
                                                                                    ...listVariables,
                                                                                    filters: {
                                                                                        ...listVariables.filters,
                                                                                        offset: ageCategoryListData.allAgeCategorys.length,
                                                                                    },
                                                                                },
                                                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                                                    if (!fetchMoreResult) return prev;
                                                                                    return Object.assign({}, prev, {
                                                                                        allAgeCategorys: [
                                                                                            ...prev.allAgeCategorys,
                                                                                            ...fetchMoreResult.allAgeCategorys,
                                                                                        ],
                                                                                    });
                                                                                },
                                                                            })
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        {translate.t("LoadMore")}{" "}
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                                    )}
                                                </>
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Loading />
                                    )}
                                </div>
                            ) : (
                                <Fragment>{error && <Error text={error} />}</Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAdd && (
                <AddEditAgeCategory
                    openCloseModal={openCloseModal}
                    selectedAgeCategoryID={selectedAgeCategoryID}
                    updated={() =>
                        refetch({
                            filters: {
                                ...listVariables.filters,
                                limit:
                                    listVariables.filters.limit > ageCategoryListData.allAgeCategorys.length
                                        ? listVariables.filters.limit
                                        : ageCategoryListData.allAgeCategorys.length,
                            },
                        })
                    }
                />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delAgeCategory} />}
        </div>
    );
};

export default AgeCategorys;
