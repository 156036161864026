import React, { Component, Fragment, useState } from "react";
import { ADD_EDIT_BANNER, GET_BANNER, GET_BANNERS } from "../Queries/banner";
import translate from "../../Translations/index";
import Notification from "../../../Library/notification";
import Loading from "../../../GlobalComponents/Loading";
import Helper from "../../../Library/helper";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import BannerLib from "../Library/banner";
import { BANNER_IMAGE_URL, SERVER_URL } from "../../../Config/index";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";

const AddEditBanner = (props) => {
    const client = useApolloClient();
    const bannerLib = new BannerLib(props);

    const [formData, setFormData] = useState({
        selectedLang: "cs",
        bannerID: props.selectedBannerID,
        langs: [],
        active: 1,
        photo: "",
    });

    const [allLanguageMutations, setAllLanguageMutations] = useState([]);
    const [mainPhoto, setMainPhoto] = useState("");

    const {
        data: bannerData,
        loading: getLoading,
        error: getError,
    } = useQuery(GET_BANNER, {
        variables: {
            bannerID: formData.bannerID,
            lang: "cs",
        },
        fetchPolicy: "network-only",
        onCompleted(data) {
            console.log(data);

            bannerLib.getData(data, formData.bannerID, formData, setFormData);
            setAllLanguageMutations(data.allLanguageMutations);

            if (formData.bannerID) {
                setMainPhoto(SERVER_URL + "/" + BANNER_IMAGE_URL + "/maxi_" + data.bannerWithLangs.photo);
            }
        },
    });

    const [addEditBanner, { data: addBannerData, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_BANNER, {
        onCompleted(data) {
            props.openCloseModal(false);
        },
        update(cache, response) {
            let notify = new Notification();
            if (formData.bannerID && formData.bannerID != 0) notify.setNotification(translate.t("SuccessfullySaved"));
            else notify.setNotification(translate.t("SuccessfullyAdded"));
        },
        onError(err) {
            const helper = new Helper();
            err = helper.getApolloErrorText(err);
            let notify = new Notification();
            notify.setNotification(err, "danger");
        },
        refetchQueries(data) {
            return [
                {
                    query: GET_BANNERS,
                    variables: { lang: "cs" },
                },
            ];
        },
    });

    const openMainImage = (e) => {
        var file = e.target.files[0];

        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;

            setMainPhoto(dataURL);
            setFormData({ ...formData, photo: file });
        };
        reader.readAsDataURL(file);
    };

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg athlete-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{formData.bannerID && formData.bannerID != 0 ? translate.t("EditBanner") : translate.t("AddBanner")}</h4>
                    {allLanguageMutations && allLanguageMutations.length > 1 && (
                        <div className="ml-auto">
                            <select
                                className="form-control"
                                name="selectedLang"
                                value={formData.selectedLang}
                                onChange={(e) => bannerLib.formHandle(e, formData, setFormData)}
                            >
                                {allLanguageMutations &&
                                    allLanguageMutations.map((item, index) => (
                                        <option key={index} value={item.suffix}>
                                            {" "}
                                            {item.suffix}{" "}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    )}
                    <button athlete="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ? (
                        <Error text={error} />
                    ) : (
                        <div>
                            {!getLoading && !addLoading ? (
                                <Fragment>
                                    <div className="row">
                                        <div className="col-12">
                                            <label className="input-label">Aktivní</label>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="active"
                                                        id="active1"
                                                        value="1"
                                                        checked={formData.active == 1 ? true : false}
                                                        onChange={(e) => bannerLib.formHandle(e, formData, setFormData)}
                                                    />
                                                    <label className="form-check-label" htmlFor="active1">
                                                        Ano
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="active"
                                                        id="active2"
                                                        value="0"
                                                        checked={formData.active == 0 ? true : false}
                                                        onChange={(e) => bannerLib.formHandle(e, formData, setFormData)}
                                                    />
                                                    <label className="form-check-label" htmlFor="active2">
                                                        Ne
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label className="input-label">Fotka</label>

                                                    <div className="form-group input-group">
                                                        <div className="custom-file">
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="customFile"
                                                                required
                                                                onChange={(e) => openMainImage(e)}
                                                            />
                                                            <label className="custom-file-label" htmlFor="customFile">
                                                                Vyberte fotku
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {mainPhoto != "" && (
                                                        <div className="form-group main-photo-container">
                                                            <div className="one-image">
                                                                <img style={{ maxWidth: "100%" }} id="main-image" src={mainPhoto} />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="input-label">Nadpis ({formData.selectedLang})</label>
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="title"
                                                                value={bannerLib.getLangValue(formData, "title")}
                                                                onChange={(e) => bannerLib.formLangHandle(e, formData, setFormData)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="input-label">Text ({formData.selectedLang})</label>
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="text"
                                                                value={bannerLib.getLangValue(formData, "text")}
                                                                onChange={(e) => bannerLib.formLangHandle(e, formData, setFormData)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="input-label">Odkaz ({formData.selectedLang})</label>
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="url"
                                                                value={bannerLib.getLangValue(formData, "url")}
                                                                onChange={(e) => bannerLib.formLangHandle(e, formData, setFormData)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => bannerLib.addBanner(addEditBanner, formData)}>
                        {formData.bannerID && formData.bannerID != 0 ? translate.t("Edit") : translate.t("Add")}
                    </button>
                    <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                        {translate.t("Close")}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AddEditBanner;
