/*
    Přidání štítku
*/
import React, { useState } from "react";
import translate from "../../Translations/index";
import { MAX_FILE_UPLOAD_SIZE, SERVER_URL, CLUB_IMAGE_URL } from "../../../Config";
import Notification from "../../../Library/notification";
import remove from "../../../Public/Images/remove.svg";
import { useMutation, useQuery } from "@apollo/client";
import { EDIT_CLUB_PHOTOS, GET_CLUB_PHOTOS } from "../Queries/club";

const EditClubLogo = (props) => {
    const [fileInputKey, setFileInputKey] = useState(0);
    const [formData, setFormData] = useState({
        clubID: props.clubID,
        logo: "",
        logoURL: "",
    });

    useQuery(GET_CLUB_PHOTOS, {
        variables: { clubID: formData.clubID },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            setFormData({
                ...formData,
                logoURL: data.club.logo ? SERVER_URL + "/" + CLUB_IMAGE_URL + "/stredni_" + data.club.logo : "",
            });
        },
    });
    const [editClubPhotos] = useMutation(EDIT_CLUB_PHOTOS, {
        onCompleted(data) {
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const openLogoFile = (e) => {
        var file = e.target.files[0];

        if (file) {
            var reader = new FileReader();
            reader.onload = (onLoadE) => {
                if (onLoadE.total / 1000000 <= MAX_FILE_UPLOAD_SIZE) {
                    setFormData({ ...formData, logoURL: reader.result, logo: file });
                } else {
                    let notify = new Notification();
                    notify.setNotification(translate.t("MaxFileSizeExceeded", { maxFileSize: MAX_FILE_UPLOAD_SIZE }), "danger");
                    setFileInputKey(fileInputKey + 1);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <div className="form-group input-group">
                        <div className="form-group">
                            <label className="input-label">{translate.t("ClubLogo")}</label>
                            {!formData.logoURL && !formData.logo ? (
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="customPhoto"
                                        name="logo"
                                        accept="image/*"
                                        onChange={(e) => openLogoFile(e)}
                                        key={fileInputKey}
                                    />
                                    <label className="custom-file-label" htmlFor="customPhoto">
                                        {translate.t("ChoosePhoto")}
                                    </label>
                                </div>
                            ) : (
                                <div>
                                    <div className="d-flex flex-column align-items-start">
                                        <img className="maxwidth-100 club-img" src={formData.logoURL} />
                                        <img
                                            onClick={() => setFormData({ ...formData, logo: "", logoURL: "" })}
                                            className="remove-icon mt-2"
                                            src={remove}
                                            title={translate.t("Remove")}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-end mt-4">
                <button
                    type="button"
                    className="btn btn-primary mr-2"
                    onClick={() =>
                        editClubPhotos({
                            variables: { ...formData },
                        })
                    }
                >
                    {translate.t("Edit")}
                </button>
                <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                    {translate.t("Close")}
                </button>
            </div>
        </>
    );
};

export default EditClubLogo;
