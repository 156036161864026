/**
 * Třída pro Content manager obsahující funkce pro správu elementů
 */

import {
    SECTION,
    COLUMNS,
    COLUMN,
    HEADLINE,
    ELEMENTS_LIST,
    PARAGRAPH,
    IMAGE,
    BUTTON,
    VIDEO,
    ARTICLES,
    NEWSLETTER,
    HTML_ELM,
    BANNERS,
    LIST,
    LISTITEM,
    TURNAMENTS,
    ATHLETS,
    LADDERS,
} from "./elements";
import { UPLOAD_IMAGE, REMOVE_IMAGE, UPDATE_CONTENT, GET_CONTENT_MANAGER_DATA } from "../Queries/contentManager";
import { CONTENT_MANAGER_IMAGE_URL, SERVER_URL } from "../../../Config/index";
import Notification from "../../../Library/notification";

class ContentManager {
    constructor(parent, client) {
        this.parent = parent;
        this.client = client;
    }

    async getData(linkID) {
        var data = await this.client.query({
            query: GET_CONTENT_MANAGER_DATA,
            variables: {
                onlyEnabled: true,
                linkID,
            },
        });

        var mainLang = "";
        for (let i in data.data.allLanguageMutations) {
            if (data.data.allLanguageMutations[i].main == 1) mainLang = data.data.allLanguageMutations[i].suffix;
        }

        var stateData = {
            lang: mainLang,
            allLanguages: data.data.allLanguageMutations,
        };

        if (data.data.linkContent.content && data.data.linkContent.content != "") {
            stateData.content = JSON.parse(data.data.linkContent.content);
        }

        this.parent.setState(stateData);
    }

    /**
     * Ulo6en9 obsahu do db
     */

    saveContent(linkID) {
        const { linkContent, allLanguageMutations } = this.client.readQuery({
            query: GET_CONTENT_MANAGER_DATA,
            variables: {
                onlyEnabled: true,
                linkID,
            },
        });

        var content = this.parent.state.content;
        content = JSON.stringify(content);

        this.client.mutate({
            mutation: UPDATE_CONTENT,
            variables: {
                content,
                linkID,
            },
            update: () => {
                let notify = new Notification();
                notify.setNotification("Úspěšně uloženo");

                linkContent.content = content;

                this.client.writeQuery({
                    query: GET_CONTENT_MANAGER_DATA,
                    variables: {
                        onlyEnabled: true,
                        linkID,
                    },
                    data: {
                        linkContent,
                        allLanguageMutations,
                    },
                });
            },
        });
    }

    /**
     * Přidání nové sekce
     * @param {*} index index sekce za kterou se má vložit nová sekce
     */

    addSection(index) {
        var arr = [...this.parent.state.content.sections];
        arr.splice(index + 1, 0, SECTION);

        this.parent.setState({
            content: { ...this.parent.state.content, sections: arr },
        });
    }

    /**
     * Přidání elementu do sekce nebo sloupce
     * @param {*} element element, který se přidává
     * @param {*} index index sekce nebo sloupce do které se vkládá element (index je string několika čísel oddělených spojovníkem)
     */

    addElementTo(element, index) {
        switch (element) {
            case "headline":
                this.addElement(index, HEADLINE(this.parent.state.allLanguages));
                break;
            case "columns":
                this.addElement(index, COLUMNS);
                break;
            case "paragraph":
                this.addElement(index, PARAGRAPH(this.parent.state.allLanguages));
                break;
            case "image":
                this.addElement(index, IMAGE);
                break;
            case "button":
                this.addElement(index, BUTTON(this.parent.state.allLanguages));
                break;
            case "video":
                this.addElement(index, VIDEO(this.parent.state.allLanguages));
                break;
            case "articles":
                this.addElement(index, ARTICLES);
                break;
            case "newsletter":
                this.addElement(index, NEWSLETTER);
                break;
            case "html":
                this.addElement(index, HTML_ELM(this.parent.state.allLanguages));
                break;
            case "banners":
                this.addElement(index, BANNERS);
                break;
            case "list":
                this.addElement(index, LIST(this.parent.state.allLanguages));
                break;
            case "turnaments":
                this.addElement(index, TURNAMENTS);
                break;
            case "ladders":
                this.addElement(index, LADDERS);
                break;
            case "athlets":
                this.addElement(index, ATHLETS);
                break;
        }
    }

    /**
     * Přidání elementu
     * @param {*} index index sekce nebo sloupce, kde se element vkládá (index je string několika čísel oddělených spojovníkem)
     * @param {*} element element, který se přidává
     */

    addElement(index, element) {
        this.hideAllMenus().then(() => {
            index = index.split("-");

            if (index.length == 1) {
                var arr = [...this.parent.state.content.sections];
                arr[index[0]] = { ...arr[index[0]], showAddElements: false, elements: [...arr[index[0]].elements, element] };

                this.parent.setState({
                    content: { ...this.parent.state.content, sections: arr },
                });
            } else {
                var arr = [...this.parent.state.content.sections];
                arr[index[0]] = this.recursiveAddElement(index, arr[index[0]], 1, element);

                this.parent.setState({
                    content: { ...this.parent.state.content, sections: arr },
                });
            }
        });
    }

    /**
     * POkud se element přidává do zanořeých sloupců, tak pro zanořování se používá tato metoda
     * @param {*} index index sekce nebo sloupce, kde se element vkládá (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole
     * @param {*} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
     * @param {*} element element, který se přidává
     */

    recursiveAddElement(index, arrPrevious, count, element) {
        var arr = [...arrPrevious.elements];
        var ar = [...arr[index[count]].columns.cols];

        if (count + 2 == index.length) {
            ar[index[count + 1]] = { ...ar[index[count + 1]], showAddElements: false, elements: [...ar[index[count + 1]].elements, element] };
            arr[index[count]] = { ...arr[index[count]], columns: { ...arr[index[count]].columns, cols: ar } };

            return { ...arrPrevious, elements: arr };
        } else {
            ar[index[count + 1]] = this.recursiveAddElement(index, ar[index[count + 1]], count + 2, element);
            arr[index[count]] = { ...arr[index[count]], columns: { ...arr[index[count]].columns, cols: ar } };

            return { ...arrPrevious, elements: arr };
        }
    }

    /**
     * Vzbere element a zobrazí jeho menu
     * @param {*} type typ elementu u kterého se má zobrazit nastavení
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     */
    selectElement(e, type, index, stopPropagation = true) {
        console.log(stopPropagation);
        if (stopPropagation) {
            e.preventDefault();
            e.stopPropagation();
        }

        index = index.split("-");

        this.hideAllMenus().then(() => {
            var arr = [...this.parent.state.content.sections];
            arr = this.recursiveSelectElement(index, arr, 0, type);

            this.parent.setState({
                content: { ...this.parent.state.content, sections: arr },
            });
        });
    }

    /**
     * Rekurentně najde element dle indexu a zobrazí mu menu
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole, které e naplňuje
     * @param {*} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
     * @param {*} type typ elementu (např headline,paragraph,...)
     */

    recursiveSelectElement(index, arrPrevious, count, type) {
        if (type == "column") {
            if (count + 3 == index.length) {
                var arr = [...arrPrevious];
                var ar = [...arrPrevious[index[count]].elements];
                var a = [...arrPrevious[index[count]].elements[index[count + 1]].columns.cols];

                a[index[count + 2]] = { ...a[index[count + 2]], showMenu: true };
                ar[index[count + 1]] = { ...ar[index[count + 1]], columns: { ...ar[index[count + 1]].columns, cols: a } };
                arr[index[count]] = { ...arr[index[count]], elements: ar };

                return arr;
            } else {
                var arr = [...arrPrevious];
                var ar = [...arrPrevious[index[count]].elements];
                var a = [...arrPrevious[index[count]].elements[index[count + 1]].columns.cols];

                ar[index[count + 1]] = {
                    ...ar[index[count + 1]],
                    columns: { ...ar[index[count + 1]].columns, cols: this.recursiveSelectElement(index, a, count + 2, type) },
                };
                arr[index[count]] = { ...arr[index[count]], elements: ar };

                return arr;
            }
        } else {
            if (count + 2 == index.length) {
                var arr = [...arrPrevious];
                var ar = [...arrPrevious[index[count]].elements];

                ar[index[count + 1]] = { ...ar[index[count + 1]], [type]: { ...ar[index[count + 1]][type], showMenu: !ar[index[count + 1]][type].showMenu } };
                arr[index[count]] = { ...arr[index[count]], elements: ar };

                return arr;
            } else {
                var arr = [...arrPrevious];
                var ar = [...arrPrevious[index[count]].elements];
                var a = [...arrPrevious[index[count]].elements[index[count + 1]].columns.cols];

                ar[index[count + 1]] = {
                    ...ar[index[count + 1]],
                    columns: { ...ar[index[count + 1]].columns, cols: this.recursiveSelectElement(index, a, count + 2, type) },
                };
                arr[index[count]] = { ...arr[index[count]], elements: ar };

                return arr;
            }
        }
    }

    /**
     * Odstranění elementu
     * @param {*} index index elementu který se má odstranit (index je string několika čísel oddělených spojovníkem)
     */

    removeElement(index) {
        index = index.split("-");

        var arr = [...this.parent.state.content.sections];
        arr = this.recursiveRemoveElement(index, arr, 0);

        this.parent.setState({
            content: { ...this.parent.state.content, sections: arr },
        });
    }

    /**
     * Rekurentně odebere element
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole, které se naplňuje
     * @param {*} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
     */

    recursiveRemoveElement(index, arrPrevious, count) {
        if (count + 2 == index.length) {
            //jestliže se maže element obrázek
            if (arrPrevious[index[count]].elements[index[count + 1]].image && arrPrevious[index[count]].elements[index[count + 1]].image.url != "") {
                var fileName = arrPrevious[index[count]].elements[index[count + 1]].image.url.split("/");
                fileName = fileName.pop();
                this.removeImage(fileName);
            }

            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements.slice(0, index[count + 1]), ...arrPrevious[index[count]].elements.slice(index[count + 1] + 1)];

            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        } else {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];
            var a = [...arrPrevious[index[count]].elements[index[count + 1]].columns.cols];

            ar[index[count + 1]] = {
                ...ar[index[count + 1]],
                columns: { ...ar[index[count + 1]].columns, cols: this.recursiveRemoveElement(index, a, count + 2) },
            };
            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        }
    }

    /**
     * Nastavení elementu Nadpis
     * @param {*} e ukazatel na položku co se nastavuje
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     */

    setElementData(e, index, element, val, callBack, setLangs, focusIndex = null) {
        var value = (e.target && e.target.value) || val;
        var type = (e.target && e.target.name) || (e._targetInst && e._targetInst.memoizedProps.name) || e;

        index = index.split("-");

        /*
        if(element != "html"){
            value = value.replace(/<[^>]*>?/gm, '');
        }*/

        if (!value) value = "";

        var arr = [...this.parent.state.content.sections];
        arr = this.recursiveSetElementData(index, arr, 0, type, value, element, setLangs, focusIndex);

        this.parent.setState(
            {
                content: { ...this.parent.state.content, sections: arr },
            },
            () => {
                if (callBack) callBack();
            }
        );
    }

    /**
     * Rekurentně najde element dle indexu a uloží mu nastavení
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole, které e naplňuje
     * @param {*} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
     * @param {*} type typ nastavení (např h1, textAlign,...)
     * @param {*} value hodnota nastavení
     * @param {*} element elemnt kterého se nastavení týká
     */

    recursiveSetElementData(index, arrPrevious, count, type, value, element, setLangs, focusIndex) {
        if (element == "column") {
            if (count + 3 == index.length) {
                var arr = [...arrPrevious];
                var ar = [...arrPrevious[index[count]].elements];
                var a = [...arrPrevious[index[count]].elements[index[count + 1]].columns.cols];

                a[index[count + 2]] = { ...a[index[count + 2]], [type]: value };
                ar[index[count + 1]] = { ...ar[index[count + 1]], columns: { ...ar[index[count + 1]].columns, cols: a } };
                arr[index[count]] = { ...arr[index[count]], elements: ar };

                return arr;
            } else {
                var arr = [...arrPrevious];
                var ar = [...arrPrevious[index[count]].elements];
                var a = [...arrPrevious[index[count]].elements[index[count + 1]].columns.cols];

                ar[index[count + 1]] = {
                    ...ar[index[count + 1]],
                    columns: {
                        ...ar[index[count + 1]].columns,
                        cols: this.recursiveSetElementData(index, a, count + 2, type, value, element, setLangs, focusIndex),
                    },
                };
                arr[index[count]] = { ...arr[index[count]], elements: ar };

                return arr;
            }
        } else {
            if (count + 2 == index.length) {
                var arr = [...arrPrevious];
                var ar = [...arrPrevious[index[count]].elements];

                if (setLangs) {
                    if (focusIndex || focusIndex == 0) {
                        var arValue = [...ar[index[count + 1]][element].listItems];
                        arValue[focusIndex] = { ...arValue[focusIndex], [type]: { ...arValue[focusIndex][type], [this.parent.state.lang]: value } };

                        ar[index[count + 1]] = { ...ar[index[count + 1]], [element]: { ...ar[index[count + 1]][element], listItems: arValue } };
                    } else {
                        ar[index[count + 1]] = {
                            ...ar[index[count + 1]],
                            [element]: {
                                ...ar[index[count + 1]][element],
                                [type]: { ...ar[index[count + 1]][element][type], [this.parent.state.lang]: value },
                            },
                        };
                    }
                } else {
                    if (focusIndex || focusIndex == 0) {
                        var arValue = [...ar[index[count + 1]][element].listItems];
                        arValue[focusIndex] = { ...arValue[focusIndex], [type]: value };

                        ar[index[count + 1]] = { ...ar[index[count + 1]], [element]: { ...ar[index[count + 1]][element], listItems: arValue } };
                    } else {
                        ar[index[count + 1]] = { ...ar[index[count + 1]], [element]: { ...ar[index[count + 1]][element], [type]: value } };
                    }
                }

                arr[index[count]] = { ...arr[index[count]], elements: ar };

                return arr;
            } else {
                var arr = [...arrPrevious];
                var ar = [...arrPrevious[index[count]].elements];
                var a = [...arrPrevious[index[count]].elements[index[count + 1]].columns.cols];

                ar[index[count + 1]] = {
                    ...ar[index[count + 1]],
                    columns: {
                        ...ar[index[count + 1]].columns,
                        cols: this.recursiveSetElementData(index, a, count + 2, type, value, element, setLangs, focusIndex),
                    },
                };
                arr[index[count]] = { ...arr[index[count]], elements: ar };

                return arr;
            }
        }
    }

    deleteListItem(index, focusIndex, callBack) {
        index = index.split("-");

        var arr = [...this.parent.state.content.sections];
        arr = this.recursiveDeleteListItem(index, arr, 0, focusIndex);

        this.parent.setState(
            {
                content: { ...this.parent.state.content, sections: arr },
            },
            () => {
                if (callBack) callBack();
            }
        );
    }

    recursiveDeleteListItem(index, arrPrevious, count, focusIndex) {
        if (count + 2 == index.length) {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];

            var listItems = [...ar[index[count + 1]].list.listItems];

            ar[index[count + 1]] = {
                ...ar[index[count + 1]],
                list: { ...ar[index[count + 1]].list, listItems: [...listItems.slice(0, focusIndex), ...listItems.slice(focusIndex + 1)] },
            };

            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        } else {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];
            var a = [...arrPrevious[index[count]].elements[index[count + 1]].columns.cols];

            ar[index[count + 1]] = {
                ...ar[index[count + 1]],
                columns: { ...ar[index[count + 1]].columns, cols: this.recursiveSetElementData(index, a, count + 2, focusIndex) },
            };
            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        }
    }

    addListItem(index, focusIndex, langs, callBack) {
        index = index.split("-");

        var arr = [...this.parent.state.content.sections];
        arr = this.recursiveAddListItem(index, arr, 0, focusIndex, langs);

        this.parent.setState(
            {
                content: { ...this.parent.state.content, sections: arr },
            },
            () => {
                if (callBack) callBack();
            }
        );
    }

    recursiveAddListItem(index, arrPrevious, count, focusIndex, langs) {
        if (count + 2 == index.length) {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];

            var listItems = [...ar[index[count + 1]].list.listItems];

            ar[index[count + 1]] = {
                ...ar[index[count + 1]],
                list: {
                    ...ar[index[count + 1]].list,
                    listItems: [...listItems.slice(0, focusIndex + 1), LISTITEM(langs, true), ...listItems.slice(focusIndex + 1)],
                },
            };

            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        } else {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];
            var a = [...arrPrevious[index[count]].elements[index[count + 1]].columns.cols];

            ar[index[count + 1]] = {
                ...ar[index[count + 1]],
                columns: { ...ar[index[count + 1]].columns, cols: this.recursiveSetElementData(index, a, count + 2, focusIndex, langs) },
            };
            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        }
    }

    /**
     * možnost zobrazit dropdown menu s jednotlivými elemety, které chceme přidat buď v sekci nebo ve sloupcích
     * @param {*} index index sekce nebo sloupce (index je string několika čísel oddělených spojovníkem)
     */

    showAddElements(index) {
        index = index.split("-");

        if (index.length == 1) {
            var arr = [...this.parent.state.content.sections];
            arr[index[0]] = { ...arr[index[0]], showAddElements: !this.parent.state.content.sections[index[0]].showAddElements };

            this.parent.setState({
                content: { ...this.parent.state.content, sections: arr },
            });
        } else {
            var arr = [...this.parent.state.content.sections];
            arr[index[0]] = this.recursiveShowAddElement(index, 1, arr[index[0]]);

            this.parent.setState({
                content: { ...this.parent.state.content, sections: arr },
            });
        }
    }

    /**
     * Pokud chceme zobrazit dropdown menu v zanořených sloupcích, použijeme rekurzi
     * @param {*} index index sekce nebo sloupce (index je string několika čísel oddělených spojovníkem)
     * @param {*} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
     * @param {*} arr předchozí pole
     */

    recursiveShowAddElement(index, count, arr) {
        if (count + 2 == index.length) {
            var a = [...arr.elements];
            var ar = [...arr.elements[index[count]].columns.cols];

            ar[index[count + 1]] = { ...ar[index[count + 1]], showAddElements: !ar[index[count + 1]].showAddElements };
            a[index[count]] = { ...a[index[count]], columns: { ...a[index[count]].columns, cols: ar } };

            return { ...arr, elements: a };
        } else {
            var a = [...arr.elements];
            var ar = [...arr.elements[index[count]].columns.cols];

            ar[index[count + 1]] = this.recursiveShowAddElement(index, count + 2, ar[index[count + 1]]);
            a[index[count]] = { ...a[index[count]], columns: { ...a[index[count]].columns, cols: ar } };

            return { ...arr, elements: a };
        }
    }

    /**
     * Nastavení elementu zobrazení nastavení
     * @param {*} type typ elementu u kterého se má zobrazit nastavení
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     */
    showSettings(type, index) {
        index = index.split("-");

        var arr = [...this.parent.state.content.sections];
        arr = this.recursiveShowSettings(index, arr, 0, type);

        this.parent.setState({
            content: { ...this.parent.state.content, sections: arr },
        });
    }

    /**
     * Rekurentně najde element dle indexu a zobrazí mu nastavení
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole, které e naplňuje
     * @param {*} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
     * @param {*} type typ elementu (např headline,paragraph,...)
     */

    recursiveShowSettings(index, arrPrevious, count, type) {
        if (type == "column") {
            if (count + 3 == index.length) {
                var arr = [...arrPrevious];
                var ar = [...arrPrevious[index[count]].elements];
                var a = [...arrPrevious[index[count]].elements[index[count + 1]].columns.cols];

                a[index[count + 2]] = { ...a[index[count + 2]], showSettings: !a[index[count + 2]].showSettings };
                ar[index[count + 1]] = { ...ar[index[count + 1]], columns: { ...ar[index[count + 1]].columns, cols: a } };
                arr[index[count]] = { ...arr[index[count]], elements: ar };

                return arr;
            } else {
                var arr = [...arrPrevious];
                var ar = [...arrPrevious[index[count]].elements];
                var a = [...arrPrevious[index[count]].elements[index[count + 1]].columns.cols];

                ar[index[count + 1]] = {
                    ...ar[index[count + 1]],
                    columns: { ...ar[index[count + 1]].columns, cols: this.recursiveShowSettings(index, a, count + 2, type) },
                };
                arr[index[count]] = { ...arr[index[count]], elements: ar };

                return arr;
            }
        } else {
            if (count + 2 == index.length) {
                var arr = [...arrPrevious];
                var ar = [...arrPrevious[index[count]].elements];

                ar[index[count + 1]] = {
                    ...ar[index[count + 1]],
                    [type]: { ...ar[index[count + 1]][type], showSettings: !ar[index[count + 1]][type].showSettings },
                };
                arr[index[count]] = { ...arr[index[count]], elements: ar };

                return arr;
            } else {
                var arr = [...arrPrevious];
                var ar = [...arrPrevious[index[count]].elements];
                var a = [...arrPrevious[index[count]].elements[index[count + 1]].columns.cols];

                ar[index[count + 1]] = {
                    ...ar[index[count + 1]],
                    columns: { ...ar[index[count + 1]].columns, cols: this.recursiveShowSettings(index, a, count + 2, type) },
                };
                arr[index[count]] = { ...arr[index[count]], elements: ar };

                return arr;
            }
        }
    }

    /**
     * Nastavení elementu zobrazení nastavení odkazu
     * @param {*} type typ elementu u kterého se má zobrazit nastavení odkazu
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     */
    showLinkSettings(type, index, callBack) {
        index = index.split("-");

        var arr = [...this.parent.state.content.sections];
        arr = this.recursiveShowLinkSettings(index, arr, 0, type);

        this.parent.setState(
            {
                content: { ...this.parent.state.content, sections: arr },
            },
            () => {
                if (callBack) callBack();
            }
        );
    }

    /**
     * Rekurentně najde element dle indexu a zobrazí mu nastavení odkazu
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole, které e naplňuje
     * @param {*} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
     * @param {*} type typ elementu (např headline,paragraph,...)
     */

    recursiveShowLinkSettings(index, arrPrevious, count, type) {
        if (count + 2 == index.length) {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];

            ar[index[count + 1]] = {
                ...ar[index[count + 1]],
                [type]: { ...ar[index[count + 1]][type], showLinkSettings: !ar[index[count + 1]][type].showLinkSettings },
            };
            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        } else {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];
            var a = [...arrPrevious[index[count]].elements[index[count + 1]].columns.cols];

            ar[index[count + 1]] = {
                ...ar[index[count + 1]],
                columns: { ...ar[index[count + 1]].columns, cols: this.recursiveShowLinkSettings(index, a, count + 2, type) },
            };
            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        }
    }

    /**
     * Nastaví počet sloupců elementu "sloupec"
     * @param {*} e ukazatel na selectbox ve kterém se vybírá počet sloupců
     * @param {*} lastIndexes index sloupce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
     */

    setColumnsColNumber(e, lastIndexes) {
        var value = e.target.value;

        var className = "";
        if (value == 2) className = "col-12 col-sm-6";
        if (value == 3) className = "col-12 col-sm-6 col-md-4";
        if (value == 4) className = "col-12 col-sm-6 col-md-3";
        if (value == 5) className = "col-12 col-sm-6 col-md-4 col-lg";
        if (value == 6) className = "col-12 col-sm-6 col-md-4 col-lg-2";

        var colsArr = [];
        for (let i = 1; i <= value; i++) {
            var column = { ...COLUMN };
            column.className = className;
            colsArr.push(column);
        }

        lastIndexes = lastIndexes.split("-");

        this.hideAllMenus().then(() => {
            var arr = [...this.parent.state.content.sections];
            arr = this.recursiveSetColumnsColNumber(lastIndexes, arr, 0, colsArr);

            this.parent.setState({
                content: { ...this.parent.state.content, sections: arr },
            });
        });
    }

    /**
     * Rekurentně nastaví počet sloupců elementu sloupce
     * @param {*} index index sloupce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole do kterého se vše ukládá
     * @param {*} count počet podle kterého se vyýbírá z indexu hodnota, na které se nachází element
     * @param {*} colsArr sloupce, které se vloží do elementu
     */

    recursiveSetColumnsColNumber(index, arrPrevious, count, colsArr) {
        if (count + 2 == index.length) {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];

            ar[index[count + 1]] = { ...ar[index[count + 1]], columns: { ...ar[index[count + 1]].columns, cols: colsArr } };
            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        } else {
            var arr = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];
            var a = [...arrPrevious[index[count]].elements[index[count + 1]].columns.cols];

            ar[index[count + 1]] = {
                ...ar[index[count + 1]],
                columns: { ...ar[index[count + 1]].columns, cols: this.recursiveSetColumnsColNumber(index, a, count + 2, colsArr) },
            };
            arr[index[count]] = { ...arr[index[count]], elements: ar };

            return arr;
        }
    }

    /**
     * Nastavení sekce - nastavuje se vnitřní šířka, jestli je pevná nebo nebo ne
     * @param {*} e ukazatel na selectbox ve kterém se vybírá tato hodnota
     * @param {*} index index sekce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
     */

    setSectionFullWidth(e, index) {
        var val = e.target.value;

        var arr = [...this.parent.state.content.sections];
        arr[index] = { ...arr[index], fullWidth: val };

        this.parent.setState({
            content: { ...this.parent.state.content, sections: arr },
        });
    }

    /**
     * Nastavení sekce - nastavuje se vnitřní výška, jestli je automativcká nebo na celou výšku
     * @param {*} e ukazatel na selectbox ve kterém se vybírá tato hodnota
     * @param {*} index index sekce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
     */

    setSectionFullHeight(e, index) {
        var val = e.target.value;

        var arr = [...this.parent.state.content.sections];
        arr[index] = { ...arr[index], fullHeight: val };

        this.parent.setState({
            content: { ...this.parent.state.content, sections: arr },
        });
    }

    /**
     * Nastavení pozadí sekce - nastavuje se classa
     * @param {*} e ukazatel na selectbox ve kterém se vybírá tato hodnota
     * @param {*} index index sekce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
     */

    setSectionBackgroundColorClass(e, index) {
        var val = e.target.value;

        var arr = [...this.parent.state.content.sections];
        arr[index] = { ...arr[index], backgroundColorClass: val };

        this.parent.setState({
            content: { ...this.parent.state.content, sections: arr },
        });
    }

    /**
     * Nastavení odsazení sekce
     * @param {*} e ukazatel na selectbox ve kterém se vybírá tato hodnota
     * @param {*} index index sekce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
     */

    setSectionPaddingClass(e, index) {
        var val = e.target.value;

        var arr = [...this.parent.state.content.sections];
        arr[index] = { ...arr[index], paddingClass: val };

        this.parent.setState({
            content: { ...this.parent.state.content, sections: arr },
        });
    }

    /**
     * Nastavení názvu
     * @param {*} e ukazatel na selectbox ve kterém se vybírá tato hodnota
     * @param {*} index index sekce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
     */

    setSectionName(e, index) {
        var val = e.target.value;

        var arr = [...this.parent.state.content.sections];
        arr[index] = { ...arr[index], sectionName: { ...arr[index].sectionName, [this.parent.state.lang]: val } };

        this.parent.setState({
            content: { ...this.parent.state.content, sections: arr },
        });
    }

    /**
     * Nastavení třídy
     * @param {*} e ukazatel na selectbox ve kterém se vybírá tato hodnota
     * @param {*} index index sekce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
     */

    setSectionClassName(e, index) {
        var val = e.target.value;

        var arr = [...this.parent.state.content.sections];
        arr[index] = { ...arr[index], className: val };

        this.parent.setState({
            content: { ...this.parent.state.content, sections: arr },
        });
    }

    /**
     * Odstranení sekce
     * @param {*} index index sekce která se odstraňuje (index je string několika čísel oddělených spojovníkem)
     */

    removeSection(index) {
        //pokud existuje obrázek na pozadí
        if (this.parent.state.content.sections[index].backgroundImage != "") {
            var filename = this.parent.state.content.sections[index].backgroundImage.split("/");
            filename = filename.pop();

            this.removeImage(filename);
        }

        this.goThroughtSectionElements(index, true);

        var arr = [...this.parent.state.content.sections.slice(0, index), ...this.parent.state.content.sections.slice(index + 1)];

        this.parent.setState({
            content: { ...this.parent.state.content, sections: arr },
        });
    }

    /**
     * Zobrazení nástrojů pro celkovou správu content manageru
     */

    showHideHelpers() {
        this.parent.setState({
            showHelpers: !this.parent.state.showHelpers,
        });
    }

    /**
     * funkce projede všechny elementy v sekci a pokud je eleting na true vymaže fotky
     * @param {*} deleting vymeže fotky
     */
    goThroughtSectionElements(i, deleting) {
        var sections = [...this.parent.state.content.sections];

        for (var j = 0; j < sections[i].elements.length; j++) {
            for (let el of ELEMENTS_LIST) {
                if (sections[i].elements[j][el]) {
                    if (el == "image") {
                        if (deleting) {
                            if (sections[i].elements[j][el].url != "") {
                                var fileName = sections[i].elements[j][el].url.split("/");
                                fileName = fileName.pop();
                                this.removeImage(fileName);
                            }
                        }
                    }
                    if (el == "columns") {
                        this.recursiveGoThroughtSectionElements(sections[i].elements[j][el].cols, deleting);
                    }
                }
            }
        }
    }

    /**
     * Rekurze pro prostupování elementama v sekci
     * @param {*} arr
     * @param {*} deleting
     */

    recursiveGoThroughtSectionElements(arr, deleting) {
        var elem = [];
        var ar = [...arr];

        for (var j = 0; j < ar.length; j++) {
            elem = [...ar[j].elements];
            if (elem.length > 0) {
                for (var i = 0; i < elem.length; i++) {
                    for (let el of ELEMENTS_LIST) {
                        if (elem[i][el]) {
                            if (el == "image") {
                                if (deleting) {
                                    if (elem[i][el].url != "") {
                                        var fileName = elem[i][el].url.split("/");
                                        fileName = fileName.pop();
                                        this.removeImage(fileName);
                                    }
                                }
                            }

                            if (el == "columns") {
                                this.recursiveGoThroughtSectionElements(elem[i][el].cols, deleting);
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * schová veškeré menu všech elementů
     */

    hideAllMenus() {
        return new Promise((resolve, rejected) => {
            var sections = [...this.parent.state.content.sections];

            for (var i in sections) {
                if (sections[i].elements.length > 0) {
                    for (var j = 0; j < sections[i].elements.length; j++) {
                        for (let el of ELEMENTS_LIST) {
                            if (sections[i].elements[j][el]) {
                                sections[i].elements[j] = { ...sections[i].elements[j], [el]: { ...sections[i].elements[j][el], showMenu: false } };
                                if (el == "columns") {
                                    sections[i].elements[j][el].cols = this.recursiveHideAllMenus(sections[i].elements[j][el].cols);
                                }
                            }
                        }
                    }
                }
            }

            this.parent.setState(
                {
                    content: { ...this.parent.state.content, sections: sections },
                },
                resolve()
            );
        });
    }

    /**
     * Rekurentně schová všechny menu ve sloupcích
     * @param {*} arr pole ve slopcích
     */
    recursiveHideAllMenus(arr) {
        var elem = [];
        var ar = [...arr];

        for (var j = 0; j < ar.length; j++) {
            ar[j] = { ...ar[j], showMenu: false };
            elem = [...ar[j].elements];
            if (elem.length > 0) {
                for (var i = 0; i < elem.length; i++) {
                    for (let el of ELEMENTS_LIST) {
                        if (elem[i][el]) {
                            elem[i] = { ...elem[i], [el]: { ...elem[i][el], showMenu: false } };
                            if (el == "columns") {
                                elem[i][el].cols = this.recursiveHideAllMenus(elem[i][el].cols);
                            }
                        }
                    }
                }
                ar[j] = { ...ar[j], elements: elem };
            }
        }

        return ar;
    }

    /**
     * Funmkce pro přesouvání sekcí. Danou sekci uloží na své místo
     * @param {*} result data z posouvače
     */

    onDragSectionEnd(result) {
        if (result.destination.index != result.source.index) {
            var arr = [...this.parent.state.content.sections];

            if (result.source.index < result.destination.index) {
                arr.splice(result.destination.index + 1, 0, arr[result.source.index]);
                arr = [...arr.slice(0, result.source.index), ...arr.slice(result.source.index + 1)];
            } else {
                arr.splice(result.destination.index, 0, arr[result.source.index]);
                arr = [...arr.slice(0, result.source.index + 1), ...arr.slice(result.source.index + 2)];
            }

            this.parent.setState({
                content: { ...this.parent.state.content, sections: arr },
            });
        }
    }

    /**
     * posunutí elementu
     * @param {*} index index sekce nebo sloupce, kde se element vkládá (index je string několika čísel oddělených spojovníkem)
     * @param {*} result data z posouvace
     */

    onDragEnd(result, index) {
        console.log(result);
        console.log(index);

        if (result.destination.index != result.source.index) {
            index = index.split("-");

            if (index.length == 1) {
                var arr = [...this.parent.state.content.sections];
                var elements = [...arr[index[0]].elements];

                if (result.source.index < result.destination.index) {
                    elements.splice(result.destination.index + 1, 0, elements[result.source.index]);
                    elements = [...elements.slice(0, result.source.index), ...elements.slice(result.source.index + 1)];
                } else {
                    elements.splice(result.destination.index, 0, elements[result.source.index]);
                    elements = [...elements.slice(0, result.source.index + 1), ...elements.slice(result.source.index + 2)];
                }

                arr[index[0]] = { ...arr[index[0]], elements: elements };

                this.parent.setState({
                    content: { ...this.parent.state.content, sections: arr },
                });
            } else {
                var arr = [...this.parent.state.content.sections];
                arr[index[0]] = this.recursiveonDragEnd(index, arr[index[0]], 1, result);

                this.parent.setState({
                    content: { ...this.parent.state.content, sections: arr },
                });
            }
        }
    }

    /**
     * POkud se element přesuvá v zanořeých sloupců, tak pro zanořování se používá tato metoda
     * @param {*} index index sekce nebo sloupce, kde se element vkládá (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole
     * @param {*} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
     * @param {*} result data z posouvace
     */

    recursiveonDragEnd(index, arrPrevious, count, result) {
        var arr = [...arrPrevious.elements];
        var ar = [...arr[index[count]].columns.cols];

        if (count + 2 == index.length) {
            var elements = [...ar[index[count + 1]].elements];

            if (result.source.index < result.destination.index) {
                elements.splice(result.destination.index + 1, 0, elements[result.source.index]);
                elements = [...elements.slice(0, result.source.index), ...elements.slice(result.source.index + 1)];
            } else {
                elements.splice(result.destination.index, 0, elements[result.source.index]);
                elements = [...elements.slice(0, result.source.index + 1), ...elements.slice(result.source.index + 2)];
            }

            ar[index[count + 1]] = { ...ar[index[count + 1]], elements: elements };
            arr[index[count]] = { ...arr[index[count]], columns: { ...arr[index[count]].columns, cols: ar } };

            return { ...arrPrevious, elements: arr };
        } else {
            ar[index[count + 1]] = this.recursiveonDragEnd(index, ar[index[count + 1]], count + 2, result);
            arr[index[count]] = { ...arr[index[count]], columns: { ...arr[index[count]].columns, cols: ar } };

            return { ...arrPrevious, elements: arr };
        }
    }

    uploadSectionBackgroundImage(e, index, oldUrl) {
        var file = e.target.files[0];

        var reader = new FileReader();
        reader.onload = async () => {
            var f = "";
            if (oldUrl && oldUrl != "") {
                f = oldUrl.split("/");
                f = f.pop();
            }

            var fileName = await this.uploadImage(file, ["maxi"], f);

            var arr = [...this.parent.state.content.sections];
            arr[index] = { ...arr[index], backgroundImage: SERVER_URL + "/" + CONTENT_MANAGER_IMAGE_URL + "/maxi_" + fileName };

            this.parent.setState({
                content: { ...this.parent.state.content, sections: arr },
            });
        };
        reader.readAsDataURL(file);
    }

    uploadImageElement(e, lastIndexes, oldUrl = "") {
        var file = e.target.files[0];

        var reader = new FileReader();
        reader.onload = async () => {
            var f = "";
            if (oldUrl && oldUrl != "") {
                f = oldUrl.split("/");
                f = f.pop();
            }

            var fileName = await this.uploadImage(file, ["stredni"], f);

            this.setElementData("url", lastIndexes, "image", SERVER_URL + "/" + CONTENT_MANAGER_IMAGE_URL + "/stredni_" + fileName);
        };
        reader.readAsDataURL(file);
    }

    /**
     * Odstranení sekce
     * @param {*} index index sekce ve které se odstraňuje obrázek na pozadí
     */

    removeSectionBackgroundImage(index) {
        var arr = [...this.parent.state.content.sections];
        var filename = arr[index].backgroundImage;
        filename = filename.split("/");
        filename = filename.pop();

        this.removeImage(filename);

        arr[index] = { ...arr[index], backgroundImage: "", bacgroundFile: "" };

        this.parent.setState({
            content: { ...this.parent.state.content, sections: arr },
        });
    }

    async uploadImage(file, sizes, f) {
        //řeší se to atypicky přes vytvoření formData (zjopírování File objektu) protože z IFRAME když vyberu FIle object, tak je naplněn, ale apollo jej pošle prázdné
        let data = new FormData();
        data.append("file", file, file.name);
        let _file = data.get("file");

        var filename = await this.client.mutate({
            mutation: UPLOAD_IMAGE,
            variables: {
                f: _file,
                sizes,
                fileName: f,
            },
        });

        return filename.data.uploadImage;
    }

    removeImage(filename) {
        this.client.mutate({
            mutation: REMOVE_IMAGE,
            variables: {
                f: filename,
            },
        });
    }

    setLanguage(e) {
        var value = e.target.value;
        this.parent.setState({ lang: value });
    }
}

export default ContentManager;
