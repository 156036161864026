/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from "graphql-tag";

/**
 * add/edit sport
 */
export const ADD_EDIT_SPORT = gql`
    mutation AddEditSport($addEditData: AddEditSportData) {
        addEditSport(addEditData: $addEditData) {
            sportID
            title
        }
    }
`;

/**
 *  Sport data
 */

export const GET_SPORT = gql`
    query Sport($sportID: ID) {
        sport(sportID: $sportID) {
            sportID
            langs {
                lang
                title
            }
        }
        allLanguageMutations(onlyEnabled: true) {
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

/**
 *  Seznam veškerých sportů
 */
export const GET_SPORTS = gql`
    query AllSports($filters: SportsFilters) {
        sports(filters: $filters) {
            sportID
            title
        }
        sportsCount(filters: $filters)
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_SPORTS = gql`
    mutation deleteSports($sportIDs: ID!) {
        deleteSports(sportIDs: $sportIDs)
    }
`;
