/*
    Přidání štítku
*/
import React, { Fragment, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import Modal from "react-modal";
import { ADD_EDIT_HEAT_TEAM, GET_HEAT_TEAM } from "../Queries/heat";
import Notification from "../../../Library/notification";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import moment from "moment";

const AddEditHeatTeam = (props) => {
    const [formData, setFormData] = useState({
        heatTeamID: props.heatTeamID,
        teamID: 0,
        heatID: props.heatID,
        rank: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
        lang: translate.locale,
    });

    const {
        loading: getLoading,
        error: getError,
        data: heatTeamData,
    } = useQuery(GET_HEAT_TEAM, {
        variables: {
            heatTeamID: formData.heatTeamID,
            disciplineID: props.disciplineID,
            heatID: formData.heatID,
            heatTeamIdToInclude: props.heatTeamID,
        },
        fetchPolicy: "cache-and-network",
        onCompleted(data) {
            if (formData.heatTeamID != "") {
                console.log(data.heatTeam.time);
                console.log(moment(data.heatTeam.time, "HH:mm:ss.SSS").isValid());

                setFormData({
                    ...formData,
                    teamID: data.heatTeam.teamID,
                    rank: parseInt(data.heatTeam.rank),
                    hours: moment(data.heatTeam.time, "HH:mm:ss.SSS").isValid() ? moment(data.heatTeam.time, "HH:mm:ss.SSS").format("H") : 0,
                    minutes: moment(data.heatTeam.time, "HH:mm:ss.SSS").isValid() ? moment(data.heatTeam.time, "HH:mm:ss.SSS").format("m") : 0,
                    seconds: moment(data.heatTeam.time, "HH:mm:ss.SSS").isValid() ? moment(data.heatTeam.time, "HH:mm:ss.SSS").format("s") : 0,
                    milliseconds: moment(data.heatTeam.time, "HH:mm:ss.SSS").isValid() ? moment(data.heatTeam.time, "HH:mm:ss.SSS").format("SSS") : 0,
                });
            }
        },
    });

    const [addHeatTeam, { data, loading: addLoading, error: addError }] = useMutation(ADD_EDIT_HEAT_TEAM, {
        onCompleted(data) {
            setFormData({ ...formData, heatTeamID: data.addEditHeatTeam.heatTeamID });
            props.openCloseModal(false);
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessfullySaved"));

            !!props.updated && props.updated();
        },
        onError(err) {
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toStrng(), "danger");
        },
    });

    const addHeatTeamData = () => {
        if (!!formData.teamID) {
            let dataToSend = {
                heatTeamID: formData.heatTeamID,
                teamID: formData.teamID,
                heatID: formData.heatID,
                rank: parseInt(formData.rank),
                time: moment(`${formData.hours}:${formData.minutes}:${formData.seconds}.${formData.milliseconds}`, "H:m:s.SSS").format("HH:mm:ss.SSS"),
                lang: translate.locale,
            };
            addHeatTeam({
                variables: {
                    addEditData: dataToSend,
                },
            });
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("NotFilledRequiredFields"), "danger");
        }
    };

    if (getError) {
        const helper = new Helper();
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }

    return (
        <Modal
            className="Modal__Bootstrap modal-dialog modal-md heatTeam-modal"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">
                        {formData.heatTeamID && formData.heatTeamID != "" ? translate.t("EditHeatTeam") : translate.t("AddHeatTeam")}
                    </h4>

                    <button type="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ? (
                        <Error text={error} />
                    ) : (
                        <div>
                            {!getLoading && !addLoading ? (
                                <Fragment>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">*{translate.t("AthleteSlashTeam")}</label>
                                                <select
                                                    value={formData.teamID}
                                                    className="form-control"
                                                    onChange={(e) => setFormData({ ...formData, teamID: e.target.value })}
                                                >
                                                    <option value={0}>--{translate.t("ChooseAthleteSlashTeam")}--</option>
                                                    {heatTeamData?.heatUnassignedTeams?.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.teamID}>
                                                                {item.title}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="input-label">{translate.t("HeatRank")}</label>
                                                <select
                                                    value={formData.rank}
                                                    className="form-control"
                                                    onChange={(e) => setFormData({ ...formData, rank: e.target.value })}
                                                >
                                                    <option value={0}>{translate.t("NotRankedYet")}</option>
                                                    {[...Array(formData.heatTeamID != "" ? heatTeamData.heatTeamsCount : heatTeamData.heatTeamsCount + 1)].map(
                                                        (item, index) => {
                                                            return (
                                                                <option key={index + 1} value={index + 1}>
                                                                    {index + 1 == 1 && translate.t("FirstPlace")}
                                                                    {index + 1 == 2 && translate.t("SecondPlace")}
                                                                    {index + 1 == 3 && translate.t("ThirdPlace")}
                                                                    {index + 1 > 3 && translate.t("SpecificRankPlace", { place: index + 1 })}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="card main">
                                                <div className="card-header d-flex align-items-center">{translate.t("FinalTime")}</div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <div className="form-group with-time-separator">
                                                                <label className="input-label">{translate.t("Hod")}</label>
                                                                <div className="input-group mb-2">
                                                                    <select
                                                                        value={formData.hours}
                                                                        className="form-control"
                                                                        onChange={(e) => setFormData({ ...formData, hours: e.target.value })}
                                                                    >
                                                                        <option value={0}>0</option>
                                                                        {[...Array(72)].map((item, index) => {
                                                                            return (
                                                                                <option key={index + 1} value={index + 1}>
                                                                                    {index + 1}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <div className="time-separator">:</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-group with-time-separator">
                                                                <label className="input-label">{translate.t("Minutes")}</label>
                                                                <div className="input-group mb-2">
                                                                    <select
                                                                        value={formData.minutes}
                                                                        className="form-control"
                                                                        onChange={(e) => setFormData({ ...formData, minutes: e.target.value })}
                                                                    >
                                                                        <option value={0}>0</option>
                                                                        {[...Array(60)].map((item, index) => {
                                                                            return (
                                                                                <option key={index + 1} value={index + 1}>
                                                                                    {index + 1}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <div className="time-separator">:</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-group with-time-separator">
                                                                <label className="input-label">{translate.t("Seconds")}</label>
                                                                <div className="input-group mb-2">
                                                                    <select
                                                                        value={formData.seconds}
                                                                        className="form-control"
                                                                        onChange={(e) => setFormData({ ...formData, seconds: e.target.value })}
                                                                    >
                                                                        <option value={0}>0</option>
                                                                        {[...Array(60)].map((item, index) => {
                                                                            return (
                                                                                <option key={index + 1} value={index + 1}>
                                                                                    {index + 1}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <div className="time-separator milliseconds">,</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-group">
                                                                <label className="input-label">{translate.t("Miliseconds")}</label>
                                                                <input
                                                                    className="form-control"
                                                                    type="number"
                                                                    inputmode="numeric"
                                                                    step={1}
                                                                    value={formData.milliseconds}
                                                                    onChange={(e) => setFormData({ ...formData, milliseconds: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end mt-4">
                                        <button type="button" className="btn btn-primary mr-2" onClick={() => addHeatTeamData()}>
                                            {formData.heatTeamID && formData.heatTeamID != "" ? translate.t("Edit") : translate.t("Add")}
                                        </button>
                                        <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>
                                            {translate.t("Close")}
                                        </button>
                                    </div>
                                </Fragment>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default AddEditHeatTeam;
