import React, { Component, Fragment } from "react";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { ApolloLink, concat } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { GRAPHQL_SERVER_URL } from "./Config/index";
import withTranslation from "./Modules/Translations/withTranslation";
import { ADMIN_USER, INITIAL_DATA } from "./GlobalQueries/globalQueries";
import Loading from "./GlobalComponents/Loading";

// routování url adres dsf dsfg dfg dg df gdfg dfg df gdfg dfg rfasdf asdf asdf asd fassad
import Routes from "./Routes/index.js";

//resolvery a schema pro lokalní uložení
import { resolvers, typeDefs } from "./Resolvers/resolvers";
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import "react-notifications-component/dist/theme.css";
import { ReactNotifications } from "react-notifications-component";
import { Tooltip } from "react-tooltip";

// inicializace Cache - dá se do této cache ukládat hodnoty pro příští použití
const cache = new InMemoryCache();

// URl adresa na server pro graphql
const httpLink = new createUploadLink({
    uri: GRAPHQL_SERVER_URL,
});

//prostředník, který při každém requestu zasílá token na server z localstorage
const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext({
        headers: {
            authorization: localStorage.getItem("token") || null,
        },
    });

    return forward(operation);
});

//Apollo klient, který se přihlašuje na server
const client = new ApolloClient({
    cache,
    link: concat(authMiddleware, httpLink),
    resolvers,
    typeDefs,
});

//do cache se ukládá, jestli jsme přihlášení nebo ne

/*
cache.writeData({
    data: {
        isLoggedIn: !!localStorage.getItem("token"),
        loginPlace: localStorage.getItem("loginPlace") || "",
        notifications: [],
    },
});
client.writeQuery({
    data: {
        isLoggedIn: !!localStorage.getItem("token"),
        loginPlace: localStorage.getItem("loginPlace") || "",
        notifications: [],
    },
});
*/

client.writeQuery({
    query: INITIAL_DATA,
    data: {
        isLoggedIn: !!localStorage.getItem("token"),
        loginPlace: localStorage.getItem("loginPlace") || "",
        notifications: [],
    },
});

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.getAdminUserData();
    }

    getAdminUserData = async () => {
        const data = await client.query({
            query: ADMIN_USER,
            errorPolicy: "all",
            fetchPolicy: "network-only",
        });
        this.setState({ loading: false });
    };

    render() {
        return (
            <Fragment>
                {!this.state.loading ? (
                    <ApolloProvider client={client}>
                        <Routes />
                        <Tooltip id="simple-600" className="ub-tooltip max-width-600" />
                    </ApolloProvider>
                ) : (
                    <Loading />
                )}
                <ReactNotifications />
            </Fragment>
        );
    }
}

export default withTranslation(App, client);
