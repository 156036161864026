import React,{ Component,Fragment } from 'react';

class AddElement extends Component{

    constructor(props){
        super(props);
    }


    render(){

        const {lastIndexes,element,cm} = this.props;

        return (
           
            <div className="cm-dropdown">
                <button onClick={() => cm.showAddElements(lastIndexes)}>Přidat obsah</button>
                {element.showAddElements &&
                    
                    <Fragment>
                        <ul>
                            <li onClick={(e) => cm.addElementTo("columns",lastIndexes)}>Sloupce</li>
                            <li onClick={(e) => cm.addElementTo("headline",lastIndexes)}>Nadpis</li>
                            <li onClick={(e) => cm.addElementTo("paragraph",lastIndexes)}>Odstavec</li>
                            <li onClick={(e) => cm.addElementTo("list",lastIndexes)}>Seznamy</li>
                            <li onClick={(e) => cm.addElementTo("image",lastIndexes)}>Obrázek</li>
                            <li onClick={(e) => cm.addElementTo("button",lastIndexes)}>Tlačítko</li>
                            <li onClick={(e) => cm.addElementTo("video",lastIndexes)}>Video</li>
                            {false && <li onClick={(e) => cm.addElementTo("articles",lastIndexes)}>Články</li>}
                            {false && <li onClick={(e) => cm.addElementTo("newsletter",lastIndexes)}>Newsletter</li>}
                            <li onClick={(e) => cm.addElementTo("banners",lastIndexes)}>Seznam Bannerů</li>
                            <li onClick={(e) => cm.addElementTo("turnaments",lastIndexes)}>Seznam turnajů</li>
                            <li onClick={(e) => cm.addElementTo("ladders",lastIndexes)}>Seznam žebříčků</li>
                            <li onClick={(e) => cm.addElementTo("athlets",lastIndexes)}>Seznam atletů</li>
                            <li onClick={(e) => cm.addElementTo("html",lastIndexes)}>HTML</li>
                        </ul>
                        <div className="cm-closing-overlay" onClick={() => cm.showAddElements(lastIndexes)}></div>
                    </Fragment>
                }
            </div>
        );

    }
}

export default AddElement;