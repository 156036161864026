/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from "graphql-tag";

/**
 * add/edit club
 */
export const ADD_EDIT_CLUB = gql`
    mutation AddEditClub($addEditData: AddEditClubDataInput) {
        addEditClub(addEditData: $addEditData) {
            hashID
            name
        }
    }
`;

/**
 *  Data organizátora
 */

export const GET_CLUB = gql`
    query club($clubID: ID, $lang: String) {
        club(clubID: $clubID) {
            clubID
            hashID
            name
            web
            email
            countryID
            logo
            federationID
            sports(filters: { lang: $lang }) {
                sportID
                title
            }
        }
        allCountries {
            countryID
            name
        }
        federations {
            federationID
            name
        }
        sports(filters: { lang: $lang }) {
            sportID
            title
        }
    }
`;
export const GET_CLUB_PHOTOS = gql`
    query club($clubID: ID) {
        club(clubID: $clubID) {
            logo
        }
    }
`;

/**
 *  Seznam veškerých organizátorů
 */
export const GET_CLUBS = gql`
    query clubs($filters: ClubsFilters, $lang: String) {
        clubs(filters: $filters) {
            hashID
            clubID
            countryID
            federationID
            name
            email
            sports(filters: { lang: $lang }) {
                title
            }
            country {
                name
            }
            federation {
                name
            }
        }

        clubsCount(filters: $filters)

        allCountries {
            countryID
            name
        }
        sports(filters: { lang: $lang }) {
            sportID
            title
        }
        federations {
            federationID
            name
        }
    }
`;

/**
 * Smazání organizátorů.
 */
export const DELETE_CLUBS = gql`
    mutation DeleteClubs($clubIDs: ID!) {
        deleteClubs(clubIDs: $clubIDs)
    }
`;
/**
 * Úprava fotek organizátora
 */
export const EDIT_CLUB_PHOTOS = gql`
    mutation EditClubPhotos($clubID: ID, $logo: Upload, $logoURL: String) {
        editClubPhotos(clubID: $clubID, logo: $logo, logoURL: $logoURL)
    }
`;
